// Compatible with @progress/kendo-theme-material v.7.2.0


$tb-adjust-contrast: true;
$tb-kendo-border-radius: 4px;
$tb-kendo-appbar-padding-x: 8px;
$tb-kendo-appbar-padding-y: 8px;
$tb-kendo-appbar-border-width: 0px;
$tb-kendo-appbar-font-size: 14px;
$tb-kendo-appbar-gap: 8px;
$tb-kendo-avatar-border-width: 1px;
$tb-kendo-avatar-font-size: 14px;
$tb-kendo-badge-border-width: 1px;
$tb-kendo-bottom-nav-padding-x: 0px;
$tb-kendo-bottom-nav-font-size: 14px;
$tb-kendo-bottom-nav-letter-spacing: 0.2px;
$tb-kendo-bottom-nav-item-padding-x: 8px;
$tb-kendo-bottom-nav-item-min-width: 72px;
$tb-kendo-breadcrumb-border-width: 0px;
$tb-kendo-breadcrumb-font-size: 14px;
$tb-kendo-breadcrumb-link-padding-x: 10px;
$tb-kendo-breadcrumb-link-padding-y: 4px;
$tb-kendo-breadcrumb-link-border-radius: 4px;
$tb-kendo-breadcrumb-icon-link-padding-y: 10px;
$tb-kendo-breadcrumb-link-icon-spacing: 8px;
$tb-kendo-button-border-width: 1px;
$tb-kendo-button-padding-x: 16px;
$tb-kendo-button-padding-y: 8px;
$tb-kendo-card-padding-x: 16px;
$tb-kendo-card-padding-y: 12px;
$tb-kendo-card-border-width: 0px;
$tb-kendo-card-border-radius: 4px;
$tb-kendo-card-font-size: 14px;
$tb-kendo-card-deck-gap: 16px;
$tb-kendo-card-header-border-width: 1px;
$tb-kendo-card-body-padding-x: 16px;
$tb-kendo-card-body-padding-y: 16px;
$tb-kendo-card-footer-border-width: 1px;
$tb-kendo-card-title-margin-bottom: 12px;
$tb-kendo-card-title-font-size: 24px;
$tb-kendo-card-subtitle-margin-bottom: 12px;
$tb-kendo-card-subtitle-font-size: 14px;
$tb-kendo-card-img-max-width: 100px;
$tb-kendo-card-avatar-size: 45px;
$tb-kendo-card-actions-padding-x: 8px;
$tb-kendo-card-actions-padding-y: 8px;
$tb-kendo-card-actions-gap: 8px;
$tb-kendo-card-deck-scroll-button-offset: 8px;
$tb-kendo-card-callout-width: 20px;
$tb-kendo-card-callout-height: 20px;
$tb-kendo-checkbox-border-width: 2px;
$tb-kendo-checkbox-label-margin-x: 4px;
$tb-kendo-checkbox-list-spacing: 16px;
$tb-kendo-checkbox-list-item-padding-x: 0px;
$tb-kendo-checkbox-list-item-padding-y: 8px;
$tb-kendo-chip-border-width: 1px;
$tb-kendo-chip-spacing: 4px;
$tb-kendo-chip-padding-x: 4px;
$tb-kendo-chip-padding-y: 4px;
$tb-kendo-chip-font-size: 14px;
$tb-kendo-color-gradient-spacer: 12px;
$tb-kendo-color-gradient-width: 294px;
$tb-kendo-color-gradient-border-width: 1px;
$tb-kendo-color-gradient-border-radius: 4px;
$tb-kendo-color-gradient-font-size: 14px;
$tb-kendo-color-gradient-canvas-border-radius: 4px;
$tb-kendo-color-gradient-canvas-rectangle-height: 180px;
$tb-kendo-color-gradient-slider-track-size: 10px;
$tb-kendo-color-gradient-slider-border-radius: 10px;
$tb-kendo-color-gradient-slider-draghandle-border-width: 3px;
$tb-kendo-color-gradient-slider-vertical-size: 180px;
$tb-kendo-color-gradient-draghandle-width: 14px;
$tb-kendo-color-gradient-draghandle-height: 14px;
$tb-kendo-color-gradient-draghandle-border-width: 1px;
$tb-kendo-color-gradient-draghandle-outline-width: 1px;
$tb-kendo-color-gradient-canvas-draghandle-margin-y: -7px;
$tb-kendo-color-gradient-canvas-draghandle-margin-x: -7px;
$tb-kendo-color-gradient-input-width: 50px;
$tb-kendo-color-gradient-input-gap: 8px;
$tb-kendo-color-gradient-input-label-gap: 4px;
$tb-kendo-color-gradient-contrast-spacer: 8px;
$tb-kendo-color-editor-spacer: 12px;
$tb-kendo-color-editor-min-width: 294px;
$tb-kendo-color-editor-border-width: 1px;
$tb-kendo-color-editor-border-radius: 4px;
$tb-kendo-color-editor-font-size: 14px;
$tb-kendo-color-editor-header-actions-gap: 8px;
$tb-kendo-color-editor-color-preview-width: 32px;
$tb-kendo-color-editor-color-preview-height: 12px;
$tb-kendo-color-editor-preview-gap: 4px;
$tb-kendo-color-editor-color-gradient-focus-outline: 2px;
$tb-kendo-color-palette-font-size: 14px;
$tb-kendo-color-palette-tile-width: 24px;
$tb-kendo-dialog-buttongroup-padding-x: 8px;
$tb-kendo-dialog-buttongroup-padding-y: 8px;
$tb-kendo-dialog-buttongroup-border-width: 1px;
$tb-kendo-dialog-button-spacing: 8px;
$tb-kendo-dock-manager-border-width: 1px;
$tb-kendo-dock-manager-pane-header-padding-y: 4px;
$tb-kendo-dock-manager-pane-header-padding-x: 24px;
$tb-kendo-dock-manager-pane-title-font-size: 16px;
$tb-kendo-dock-manager-unpinned-container-width: 300px;
$tb-kendo-dock-indicator-padding: 6px;
$tb-kendo-dock-indicator-outline-width: 1px;
$tb-kendo-dock-manager-dock-preview-border-width: 1px;
$tb-kendo-dock-manager-dock-preview-border-radius: 2px;
$tb-kendo-dropdowntree-popup-padding-x: 8px;
$tb-kendo-dropdowntree-popup-padding-y: 8px;
$tb-kendo-dropzone-padding-x: 8px;
$tb-kendo-dropzone-padding-y: 8px;
$tb-kendo-dropzone-border-width: 1px;
$tb-kendo-dropzone-min-height: 220px;
$tb-kendo-dropzone-font-size: 14px;
$tb-kendo-dropzone-icon-spacing: 24px;
$tb-kendo-dropzone-hint-spacing: 8px;
$tb-kendo-dropzone-note-font-size: 12px;
$tb-kendo-editor-border-width: 1px;
$tb-kendo-editor-font-size: 14px;
$tb-kendo-editor-resize-handle-size: 8px;
$tb-kendo-editor-resize-handle-border-width: 1px;
$tb-kendo-editor-selectednode-outline-width: 2px;
$tb-kendo-expander-spacing-y: 12px;
$tb-kendo-expander-border-width: 1px;
$tb-kendo-expander-font-size: 14px;
$tb-kendo-expander-header-padding-x: 24px;
$tb-kendo-expander-header-padding-y: 12px;
$tb-kendo-expander-indicator-margin-x: 12px;
$tb-kendo-expander-content-padding-x: 24px;
$tb-kendo-expander-content-padding-y: 24px;
$tb-kendo-filter-padding-x: 8px;
$tb-kendo-filter-line-size: 1px;
$tb-kendo-fab-font-size: 14px;
$tb-kendo-fab-padding-x: 16px;
$tb-kendo-fab-icon-padding-x: 2px;
$tb-kendo-fab-icon-spacing: 6px;
$tb-kendo-fab-items-padding-x: 0px;
$tb-kendo-fab-items-padding-y: 16px;
$tb-kendo-fab-item-text-padding-x: 4px;
$tb-kendo-fab-item-text-border-width: 1px;
$tb-kendo-fab-item-text-border-radius: 2px;
$tb-kendo-fab-item-text-font-size: 10px;
$tb-kendo-fab-item-icon-padding-x: 10px;
$tb-kendo-floating-label-font-size: 16px;
$tb-kendo-form-spacer: 32px;
$tb-kendo-form-font-size: 14px;
$tb-kendo-form-fieldset-padding: 0px;
$tb-kendo-form-legend-padding: 0px;
$tb-kendo-form-legend-font-size: 14px;
$tb-kendo-form-label-margin-bottom: 0px;
$tb-kendo-form-hint-font-size: 12px;
$tb-kendo-form-hint-margin-top: 4px;
$tb-kendo-horizontal-form-label-padding-top: 5px;
$tb-kendo-horizontal-form-label-margin-x: 10px;
$tb-kendo-label-optional-margin-x: 6px;
$tb-kendo-label-optional-font-size: 12px;
$tb-kendo-fieldset-margin: 30px;
$tb-kendo-grid-padding-x: 24px;
$tb-kendo-grid-padding-y: 10px;
$tb-kendo-grid-header-padding-y: 16px;
$tb-kendo-grid-grouping-header-padding-x: 8px;
$tb-kendo-grid-edit-cell-padding-y: 6px;
$tb-kendo-grid-selection-aggregates-border-width: 1px;
$tb-kendo-grid-selection-aggregates-spacing: 8px;
$tb-kendo-grid-selection-aggregates-line-height: 20px;
$tb-kendo-grid-row-resizer-height: 2px;
$tb-kendo-input-border-width: 1px;
$tb-kendo-input-padding-x: 16px;
$tb-kendo-input-padding-y: 8px;
$tb-kendo-input-font-size: 16px;
$tb-kendo-listbox-spacing: 12px;
$tb-kendo-listbox-button-spacing: 8px;
$tb-kendo-listbox-default-height: 200px;
$tb-kendo-listbox-border-width: 1px;
$tb-kendo-listbox-font-size: 14px;
$tb-kendo-listbox-item-padding-x: 16px;
$tb-kendo-listbox-item-padding-y: 8px;
$tb-kendo-listbox-drop-hint-width: 2px;
$tb-kendo-listview-padding-x: 4px;
$tb-kendo-listview-padding-y: 4px;
$tb-kendo-listview-border-width: 1px;
$tb-kendo-listview-font-size: 14px;
$tb-kendo-listview-item-padding-x: 4px;
$tb-kendo-listview-item-padding-y: 4px;
$tb-kendo-loader-container-panel-border-width: 1px;
$tb-kendo-loader-container-panel-border-radius: 4px;
$tb-kendo-menu-popup-border-width: 0px;
$tb-kendo-menu-popup-item-padding-x: 16px;
$tb-kendo-menu-popup-item-padding-y: 8px;
$tb-kendo-menu-popup-item-spacing: 0px;
$tb-kendo-menu-button-arrow-padding-x: 4px;
$tb-kendo-notification-group-gap: 8px;
$tb-kendo-notification-padding-x: 16px;
$tb-kendo-notification-padding-y: 14px;
$tb-kendo-notification-border-width: 0px;
$tb-kendo-notification-border-radius: 4px;
$tb-kendo-notification-font-size: 14px;
$tb-kendo-notification-icon-spacing: 8px;
$tb-kendo-pager-border-width: 1px;
$tb-kendo-pager-font-size: 14px;
$tb-kendo-pager-item-spacing: 0px;
$tb-kendo-popover-border-width: 1px;
$tb-kendo-popup-border-width: 0px;
$tb-kendo-popup-font-size: 14px;
$tb-kendo-popup-content-padding-x: 8px;
$tb-kendo-popup-content-padding-y: 8px;
$tb-kendo-progressbar-height: 5px;
$tb-kendo-progressbar-border-width: 0px;
$tb-kendo-progressbar-font-size: 12px;
$tb-kendo-radio-border-width: 2px;
$tb-kendo-radio-label-margin-x: 4px;
$tb-kendo-radio-list-spacing: 16px;
$tb-kendo-radio-list-item-padding-x: 0px;
$tb-kendo-radio-list-item-padding-y: 8px;
$tb-kendo-rating-font-size: 14px;
$tb-kendo-rating-container-margin-x: 4px;
$tb-kendo-rating-item-padding-x: 4px;
$tb-kendo-rating-item-padding-y: 4px;
$tb-kendo-rating-label-margin-x: 8px;
$tb-kendo-rating-label-margin-y: 4px;
$tb-kendo-scrollview-border-width: 1px;
$tb-kendo-scrollview-font-size: 14px;
$tb-kendo-scrollview-pagebutton-size: 10px;
$tb-kendo-scrollview-pager-item-spacing: 20px;
$tb-kendo-scrollview-pager-item-border-width: 0px;
$tb-kendo-skeleton-text-border-radius: 4px;
$tb-kendo-skeleton-circle-border-radius: 9999px;
$tb-kendo-split-button-arrow-padding-x: 4px;
$tb-kendo-table-border-width: 1px;
$tb-kendo-table-cell-vertical-border-width: 1px;
$tb-kendo-table-cell-horizontal-border-width: 1px;
$tb-kendo-tabstrip-item-padding-x: 24px;
$tb-kendo-tabstrip-item-padding-y: 14px;
$tb-kendo-tabstrip-item-border-width: 0px;
$tb-kendo-tabstrip-item-border-radius: 0px;
$tb-kendo-tabstrip-item-gap: 0px;
$tb-kendo-tabstrip-content-padding-x: 16px;
$tb-kendo-tabstrip-content-padding-y: 16px;
$tb-kendo-tabstrip-content-border-width: 0px;
$tb-kendo-tile-layout-border-width: 0px;
$tb-kendo-tile-layout-padding-x: 16px;
$tb-kendo-tile-layout-card-border-width: 1px;
$tb-kendo-tile-layout-hint-border-width: 1px;
$tb-kendo-tile-layout-hint-border-radius: 4px;
$tb-kendo-toolbar-font-size: 14px;
$tb-kendo-toolbar-flat-border-width: 1px;
$tb-kendo-tooltip-padding-y: 3px;
$tb-kendo-tooltip-padding-x: 8px;
$tb-kendo-tooltip-border-width: 0px;
$tb-kendo-tooltip-border-radius: 4px;
$tb-kendo-tooltip-font-size: 12px;
$tb-kendo-tooltip-title-font-size: 15px;
$tb-kendo-tooltip-callout-size: 6px;
$tb-kendo-treeview-font-size: 16px;
$tb-kendo-treeview-indent: 24px;
$tb-kendo-treeview-item-padding-x: 16px;
$tb-kendo-treeview-item-padding-y: 8px;
$tb-kendo-treeview-item-border-width: 0px;
$tb-kendo-upload-border-width: 1px;
$tb-kendo-upload-font-size: 14px;
$tb-kendo-upload-max-height: 300px;
$tb-kendo-upload-dropzone-padding-x: 8px;
$tb-kendo-upload-dropzone-padding-y: 8px;
$tb-kendo-upload-item-padding-x: 16px;
$tb-kendo-upload-multiple-items-spacing: 12px;
$tb-kendo-upload-validation-font-size: 12px;
$tb-kendo-upload-icon-spacing: 8px;
$tb-kendo-window-border-width: 0px;
$tb-kendo-window-border-radius: 4px;
$tb-kendo-window-font-size: 14px;
$tb-kendo-window-titlebar-padding-x: 24px;
$tb-kendo-window-titlebar-padding-y: 16px;
$tb-kendo-window-titlebar-border-width: 0px;
$tb-kendo-window-title-font-size: 20px;
$tb-kendo-window-inner-padding-x: 24px;
$tb-kendo-window-inner-padding-y: 8px;
$tb-kendo-window-buttongroup-padding-x: 8px;
$tb-kendo-window-buttongroup-padding-y: 8px;
$tb-kendo-window-buttongroup-border-width: 0px;
$tb-kendo-bottom-nav-padding-y: $tb-kendo-bottom-nav-padding-x;
$tb-kendo-bottom-nav-gap: $tb-kendo-bottom-nav-padding-x;
$tb-kendo-breadcrumb-icon-link-padding-x: $tb-kendo-breadcrumb-icon-link-padding-y;
$tb-kendo-card-header-padding-x: $tb-kendo-card-padding-x;
$tb-kendo-card-header-padding-y: $tb-kendo-card-padding-y;
$tb-kendo-card-footer-padding-x: $tb-kendo-card-padding-x;
$tb-kendo-card-footer-padding-y: $tb-kendo-card-padding-y;
$tb-kendo-card-avatar-spacing: $tb-kendo-card-header-padding-x;
$tb-kendo-color-gradient-padding-y: $tb-kendo-color-gradient-spacer;
$tb-kendo-color-gradient-padding-x: $tb-kendo-color-gradient-padding-y;
$tb-kendo-color-gradient-gap: $tb-kendo-color-gradient-spacer;
$tb-kendo-color-gradient-canvas-gap: $tb-kendo-color-gradient-spacer;
$tb-kendo-color-editor-header-padding-y: $tb-kendo-color-editor-spacer;
$tb-kendo-color-editor-header-padding-x: $tb-kendo-color-editor-header-padding-y;
$tb-kendo-color-editor-views-padding-y: $tb-kendo-color-editor-spacer;
$tb-kendo-color-editor-views-padding-x: $tb-kendo-color-editor-views-padding-y;
$tb-kendo-color-editor-views-gap: $tb-kendo-color-editor-spacer;
$tb-kendo-color-palette-tile-height: $tb-kendo-color-palette-tile-width;
$tb-kendo-dock-manager-pane-content-padding-x: $tb-kendo-dock-manager-pane-header-padding-x;
$tb-kendo-dock-manager-pane-content-padding-y: $tb-kendo-dock-manager-pane-header-padding-x;
$tb-kendo-filter-padding-y: $tb-kendo-filter-padding-x;
$tb-kendo-fab-padding-y: $tb-kendo-fab-padding-x;
$tb-kendo-fab-icon-padding-y: $tb-kendo-fab-icon-padding-x;
$tb-kendo-fab-item-text-padding-y: $tb-kendo-fab-item-text-padding-x;
$tb-kendo-fab-item-icon-padding-y: $tb-kendo-fab-item-icon-padding-x;
$tb-kendo-grid-header-padding-x: $tb-kendo-grid-padding-x;
$tb-kendo-grid-grouping-header-padding-y: $tb-kendo-grid-grouping-header-padding-x;
$tb-kendo-grid-cell-padding-x: $tb-kendo-grid-padding-x;
$tb-kendo-grid-cell-padding-y: $tb-kendo-grid-padding-y;
$tb-kendo-grid-filter-cell-padding-x: $tb-kendo-grid-padding-x;
$tb-kendo-grid-filter-cell-padding-y: $tb-kendo-grid-padding-y;
$tb-kendo-grid-edit-cell-padding-x: $tb-kendo-grid-cell-padding-x;
$tb-kendo-popover-border-radius: $tb-kendo-card-border-radius;
$tb-kendo-popover-font-size: $tb-kendo-card-font-size;
$tb-kendo-popover-header-padding-x: $tb-kendo-card-header-padding-x;
$tb-kendo-popover-header-padding-y: $tb-kendo-card-header-padding-y;
$tb-kendo-popover-header-border-width: $tb-kendo-card-header-border-width;
$tb-kendo-popover-body-padding-x: $tb-kendo-card-body-padding-x;
$tb-kendo-popover-body-padding-y: $tb-kendo-card-body-padding-y;
$tb-kendo-popover-actions-border-width: $tb-kendo-popover-border-width;
$tb-kendo-popover-callout-width: $tb-kendo-card-callout-width;
$tb-kendo-popover-callout-height: $tb-kendo-card-callout-height;
$tb-kendo-popover-callout-border-width: $tb-kendo-popover-border-width;
$tb-kendo-split-button-arrow-padding-y: $tb-kendo-button-padding-y;
$tb-kendo-tile-layout-padding-y: $tb-kendo-tile-layout-padding-x;
$tb-primary-palette-name: blueGray;
$tb-secondary-palette-name: red;
$tb-theme-type: light;
$tb-kendo-base-bg: #668092ff;
$tb-kendo-base-text: #ffffff;
$tb-kendo-base-border: rgba(0, 0, 0, 0.08);
$tb-kendo-hover-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-hover-border: rgba(0, 0, 0, 0.15);
$tb-kendo-selected-bg: #679dc1ff;
$tb-kendo-selected-text: #dfd8d8ff;
$tb-kendo-selected-border: rgba(0, 0, 0, 0.1);
$tb-kendo-disabled-text: rgba(0, 0, 0, 0.38);
$tb-kendo-appbar-light-bg: #f5f5f5;
$tb-kendo-appbar-light-text: black;
$tb-kendo-appbar-dark-bg: #424242;
$tb-kendo-appbar-dark-text: white;
$tb-kendo-bottom-nav-flat-text: rgba(0, 0, 0, 0.87);
$tb-kendo-bottom-nav-flat-bg: #ffffff;
$tb-kendo-bottom-nav-flat-border: rgba(0, 0, 0, 0.12);
$tb-kendo-breadcrumb-bg: #ffffff;
$tb-kendo-breadcrumb-text: rgba(0, 0, 0, 0.87);
$tb-kendo-breadcrumb-border: rgba(0, 0, 0, 0.12);
$tb-kendo-breadcrumb-link-text: #3f51b5;
$tb-kendo-breadcrumb-link-focus-bg: rgba(0, 0, 0, 0.12);
$tb-kendo-breadcrumb-root-link-focus-bg: rgba(0, 0, 0, 0.12);
$tb-kendo-button-disabled-bg: #e0e0e0;
$tb-kendo-button-disabled-text: rgba(0, 0, 0, 0.38);
$tb-kendo-button-disabled-border: #e0e0e0;
$tb-kendo-card-border: rgba(0, 0, 0, 0.12);
$tb-kendo-series-a: #9c27b0;
$tb-kendo-series-b: #2196f3;
$tb-kendo-series-c: #009688;
$tb-kendo-series-d: #ffeb3b;
$tb-kendo-series-e: #f44336;
$tb-kendo-series-f: #4caf50;
$tb-kendo-chart-major-lines: rgba(0, 0, 0, 0.08);
$tb-kendo-chart-minor-lines: rgba(0, 0, 0, 0.04);
$tb-kendo-checkbox-border: rgba(0, 0, 0, 0.54);
$tb-kendo-checkbox-checked-bg: #3f51b5;
$tb-kendo-checkbox-checked-text: white;
$tb-kendo-checkbox-disabled-border: #adadad;
$tb-kendo-checkbox-disabled-checked-text: black;
$tb-kendo-checkbox-invalid-text: #f31700;
$tb-kendo-checkbox-invalid-border: #f31700;
$tb-kendo-checkbox-ripple-bg: #3f51b5;
$tb-kendo-chip-solid-focus-bg: #8f8b8bff;
$tb-kendo-chip-solid-hover-bg: #a4a8b0ff;
$tb-kendo-chip-solid-selected-bg: #c2c2c2;
$tb-kendo-chip-outline-bg: #878e9fff;
$tb-kendo-chip-outline-hover-bg: #ebebeb;
$tb-kendo-chip-outline-hover-text: black;
$tb-kendo-chip-outline-selected-bg: #d6d6d6;
$tb-kendo-color-gradient-text: rgba(0, 0, 0, 0.87);
$tb-kendo-color-gradient-bg: #ffffff;
$tb-kendo-color-gradient-border: rgba(0, 0, 0, 0.12);
$tb-kendo-color-gradient-draghandle-bg: transparent;
$tb-kendo-color-gradient-draghandle-border: rgba(255, 255, 255, 0.8);
$tb-kendo-color-gradient-draghandle-focus-shadow: #000000;
$tb-kendo-color-gradient-draghandle-hover-shadow: #000000;
$tb-kendo-color-gradient-input-label-text: rgba(0, 0, 0, 0.54);
$tb-kendo-color-editor-text: rgba(0, 0, 0, 0.87);
$tb-kendo-color-editor-bg: #ffffff;
$tb-kendo-color-editor-border: rgba(0, 0, 0, 0.12);
$tb-kendo-color-editor-color-gradient-focus-outline-color: rgba(0, 0, 0, 0.3);
$tb-kendo-component-text: #ffffff;
$tb-kendo-component-border: rgba(0, 0, 0, 0.12);
$tb-kendo-dock-manager-unpinned-container-bg: #ffffff;
$tb-kendo-dock-indicator-text: #3f51b5;
$tb-kendo-dock-indicator-hover-bg: #4e5fbb;
$tb-kendo-dock-indicator-hover-text: #ffffff;
$tb-kendo-dock-manager-dock-preview-bg: rgba(63, 81, 181, 0.16);
$tb-kendo-dock-manager-dock-preview-border: #3f51b5;
$tb-kendo-dropzone-bg: #ebebeb;
$tb-kendo-dropzone-icon-text: rgba(97, 97, 97, 0.9116);
$tb-kendo-dropzone-icon-hover-text: #3f51b5;
$tb-kendo-dropzone-note-text: rgba(0, 0, 0, 0.54);
$tb-kendo-editor-placeholder-text: rgba(0, 0, 0, 0.54);
$tb-kendo-editor-selected-text: white;
$tb-kendo-editor-selected-bg: #3f51b5;
$tb-kendo-editor-highlighted-bg: #d9dcf0;
$tb-kendo-editor-resize-handle-border: #000000;
$tb-kendo-editor-resize-handle-bg: #ffffff;
$tb-kendo-editor-selectednode-outline-color: #88ccff;
$tb-kendo-expander-header-bg: transparent;
$tb-kendo-expander-header-hover-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-expander-header-focus-bg: rgba(0, 0, 0, 0.12);
$tb-kendo-expander-title-text: #e51a5f;
$tb-kendo-expander-header-sub-title-text: rgba(0, 0, 0, 0.54);
$tb-kendo-filter-preview-field-text: #3f51b5;
$tb-kendo-filter-preview-operator-text: rgba(0, 0, 0, 0.54);
$tb-kendo-floating-label-text: rgba(0, 0, 0, 0.54);
$tb-kendo-floating-label-focus-text: #3f51b5;
$tb-kendo-grid-bg: #ffffff;
$tb-kendo-grid-text: #000000d1;
$tb-kendo-grid-border: rgba(0, 0, 0, 0.12);
$tb-kendo-grid-header-bg: #ffffff;
$tb-kendo-grid-header-text: rgba(0, 0, 0, 0.54);
$tb-kendo-grid-header-border: rgba(0, 0, 0, 0.12);
$tb-kendo-grid-footer-bg: #ffffff;
$tb-kendo-grid-footer-text: rgba(0, 0, 0, 0.54);
$tb-kendo-grid-footer-border: rgba(0, 0, 0, 0.12);
$tb-kendo-grid-alt-bg: transparent;
$tb-kendo-grid-hover-bg: rgba(0, 0, 0, 0.07);
$tb-kendo-grid-selected-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-grid-row-resizer-hover-bg: rgba(0, 0, 0, 0.24);
$tb-kendo-grid-row-resizer-active-bg: #3f51b5;
$tb-kendo-input-bg: whitesmoke;
$tb-kendo-input-border: rgba(0, 0, 0, 0.38);
$tb-kendo-input-focus-border: #3f51b5;
$tb-kendo-input-disabled-bg: #fafafa;
$tb-kendo-input-disabled-text: rgba(0, 0, 0, 0.38);
$tb-kendo-input-disabled-border: rgba(0, 0, 0, 0.06);
$tb-kendo-input-placeholder-text: rgba(0, 0, 0, 0.54);
$tb-kendo-input-separator-text: rgba(0, 0, 0, 0.12);
$tb-kendo-input-prefix-text: rgba(0, 0, 0, 0.54);
$tb-kendo-input-suffix-text: rgba(0, 0, 0, 0.54);
$tb-kendo-input-invalid-border: #f31700;
$tb-kendo-input-valid-border: #37b400;
$tb-kendo-list-item-selected-text: #e51a5f;
$tb-kendo-list-no-data-text: rgba(0, 0, 0, 0.54);
$tb-kendo-list-option-label-text: rgba(0, 0, 0, 0.54);
$tb-kendo-listview-item-selected-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-listview-item-focus-bg: rgba(0, 0, 0, 0.08);
$tb-kendo-loader-secondary-bg: #000000;
$tb-kendo-loader-container-panel-bg: #ffffff;
$tb-kendo-menu-popup-bg: #ffffff;
$tb-kendo-menu-popup-text: rgba(0, 0, 0, 0.87);
$tb-kendo-menu-popup-border: rgba(0, 0, 0, 0.12);
$tb-kendo-pager-text: rgba(0, 0, 0, 0.54);
$tb-kendo-pager-focus-bg: whitesmoke;
$tb-kendo-progressbar-bg: #bac0e4;
$tb-kendo-progressbar-value-bg: #3f51b5;
$tb-kendo-progressbar-value-text: white;
$tb-kendo-circular-progressbar-arc-stroke: #3f51b5;
$tb-kendo-radio-checked-text: #3f51b5;
$tb-kendo-radio-ripple-bg: #3f51b5;
$tb-kendo-rating-icon-text: rgba(0, 0, 0, 0.54);
$tb-kendo-rating-icon-selected-text: #9b9f30ff;
$tb-kendo-rating-icon-hover-text: #9f8530ff;
$tb-kendo-rating-icon-focus-text: #9f8f30ff;
$tb-kendo-scrollview-pagebutton-primary-bg: #3f51b5;
$tb-kendo-scrollview-pagebutton-primary-border: #3f51b5;
$tb-kendo-scrollview-navigation-color: white;
$tb-kendo-scrollview-navigation-bg: rgba(0, 0, 0, 0);
$tb-kendo-scrollview-light-bg: rgba(255, 255, 255, 0.4);
$tb-kendo-scrollview-dark-bg: rgba(0, 0, 0, 0.4);
$tb-kendo-skeleton-item-bg: rgba(66, 66, 66, 0.2);
$tb-kendo-skeleton-wave-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-switch-off-track-bg: rgba(0, 0, 0, 0.38);
$tb-kendo-switch-off-thumb-bg: #7e7878ff;
$tb-kendo-switch-on-track-bg: #bfbfbf82;
$tb-kendo-table-header-text: rgba(0, 0, 0, 0.54);
$tb-kendo-table-alt-row-bg: transparent;
$tb-kendo-table-hover-bg: rgba(0, 0, 0, 0.07);
$tb-kendo-table-selected-bg: rgba(0, 0, 0, 0.04);
$tb-kendo-tabstrip-text: rgba(0, 0, 0, 0.54);
$tb-kendo-tabstrip-item-text: rgba(0, 0, 0, 0.54);
$tb-kendo-tabstrip-item-hover-text: rgba(0, 0, 0, 0.87);
$tb-kendo-tabstrip-item-selected-text: rgba(0, 0, 0, 0.87);
$tb-kendo-tabstrip-content-bg: transparent;
$tb-kendo-tile-layout-bg: #f5f5f5;
$tb-kendo-tile-layout-hint-bg: rgba(255, 255, 255, 0.2);
$tb-kendo-tooltip-bg: rgba(97, 97, 97, 0.9);
$tb-kendo-tooltip-text: #ffffff;
$tb-kendo-treeview-item-hover-bg: rgba(0, 0, 0, 0.07);
$tb-kendo-treeview-loadmore-bg: transparent;
$tb-kendo-treeview-loadmore-hover-bg: transparent;
$tb-kendo-treeview-loadmore-focus-bg: transparent;
$tb-kendo-upload-dropzone-text: rgba(0, 0, 0, 0.87);
$tb-kendo-upload-dropzone-bg: #ffffff;
$tb-kendo-upload-status-text: rgba(0, 0, 0, 0.54);
$tb-kendo-upload-icon-color: rgba(0, 0, 0, 0.54);
$tb-kendo-upload-progress-bg: #0058e9;
$tb-kendo-upload-success-text: #37b400;
$tb-kendo-upload-success-bg: #37b400;
$tb-kendo-upload-error-text: #f31700;
$tb-kendo-upload-error-bg: #f31700;
$tb-kendo-body-bg: $tb-kendo-base-bg;
$tb-kendo-hover-text: $tb-kendo-base-text;
$tb-kendo-breadcrumb-link-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-breadcrumb-root-link-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-button-bg: $tb-kendo-base-bg;
$tb-kendo-button-text: $tb-kendo-base-text;
$tb-kendo-button-border: $tb-kendo-button-bg;
$tb-kendo-card-bg: $tb-kendo-base-bg;
$tb-kendo-card-text: $tb-kendo-appbar-dark-text;
$tb-kendo-card-header-border: $tb-kendo-card-border;
$tb-kendo-card-footer-border: $tb-kendo-card-border;
$tb-kendo-card-subtitle-text: $tb-kendo-appbar-light-bg;
$tb-kendo-series-1: $tb-kendo-series-a;
$tb-kendo-checkbox-checked-border: $tb-kendo-checkbox-checked-bg;
$tb-kendo-checkbox-indeterminate-bg: $tb-kendo-checkbox-checked-bg;
$tb-kendo-checkbox-indeterminate-text: $tb-kendo-checkbox-checked-text;
$tb-kendo-checkbox-indeterminate-border: $tb-kendo-checkbox-checked-border;
$tb-kendo-checkbox-disabled-checked-bg: $tb-kendo-checkbox-disabled-border;
$tb-kendo-checkbox-disabled-checked-border: $tb-kendo-checkbox-disabled-checked-bg;
$tb-kendo-chip-solid-bg: $tb-kendo-base-bg;
$tb-kendo-chip-solid-text: $tb-kendo-button-text;
$tb-kendo-chip-solid-border: $tb-kendo-chip-solid-bg;
$tb-kendo-chip-outline-text: $tb-kendo-chip-solid-text;
$tb-kendo-chip-outline-border: $tb-kendo-chip-outline-text;
$tb-kendo-chip-outline-selected-text: $tb-kendo-chip-outline-hover-text;
$tb-kendo-color-gradient-focus-border: $tb-kendo-hover-border;
$tb-kendo-color-editor-focus-border: $tb-kendo-hover-border;
$tb-kendo-component-bg: $tb-kendo-base-bg;
$tb-kendo-dock-manager-border: $tb-kendo-base-border;
$tb-kendo-dock-manager-pane-header-bg: $tb-kendo-component-bg;
$tb-kendo-dock-indicator-bg: $tb-kendo-base-bg;
$tb-kendo-dock-indicator-outline: $tb-kendo-dock-indicator-text;
$tb-kendo-dropzone-text: $tb-kendo-base-text;
$tb-kendo-dropzone-border: $tb-kendo-base-border;
$tb-kendo-expander-text: $tb-kendo-component-text;
$tb-kendo-expander-bg: $tb-kendo-component-bg;
$tb-kendo-expander-border: $tb-kendo-component-border;
$tb-kendo-expander-header-text: $tb-kendo-expander-text;
$tb-kendo-fab-item-text: $tb-kendo-component-text;
$tb-kendo-fab-item-bg: $tb-kendo-component-bg;
$tb-kendo-fab-item-border: $tb-kendo-component-border;
$tb-kendo-fab-item-icon-text: $tb-kendo-button-text;
$tb-kendo-fab-item-icon-bg: $tb-kendo-button-bg;
$tb-kendo-fab-item-icon-border: $tb-kendo-button-border;
$tb-kendo-form-legend-border-color: $tb-kendo-component-border;
$tb-kendo-form-separator-border-color: $tb-kendo-form-legend-border-color;
$tb-kendo-grid-selection-aggregates-bg: $tb-kendo-grid-header-bg;
$tb-kendo-grid-selection-aggregates-text: $tb-kendo-grid-text;
$tb-kendo-grid-selection-aggregates-border: $tb-kendo-grid-border;
$tb-kendo-input-text: $tb-kendo-component-text;
$tb-kendo-input-hover-border: $tb-kendo-input-text;
$tb-kendo-input-outline-text: $tb-kendo-input-text;
$tb-kendo-input-outline-border: $tb-kendo-input-border;
$tb-kendo-input-outline-hover-border: $tb-kendo-input-hover-border;
$tb-kendo-input-outline-focus-border: $tb-kendo-input-focus-border;
$tb-kendo-input-flat-text: $tb-kendo-input-text;
$tb-kendo-input-flat-border: $tb-kendo-input-border;
$tb-kendo-input-flat-hover-border: $tb-kendo-input-hover-border;
$tb-kendo-input-flat-focus-border: $tb-kendo-input-focus-border;
$tb-kendo-list-bg: $tb-kendo-component-bg;
$tb-kendo-list-text: $tb-kendo-component-text;
$tb-kendo-list-border: $tb-kendo-component-border;
$tb-kendo-list-item-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-list-item-hover-text: $tb-kendo-hover-text;
$tb-kendo-list-item-focus-bg: $tb-kendo-list-item-hover-bg;
$tb-kendo-list-item-selected-bg: $tb-kendo-component-bg;
$tb-kendo-listbox-text: $tb-kendo-component-text;
$tb-kendo-listbox-bg: $tb-kendo-component-bg;
$tb-kendo-listbox-border: $tb-kendo-component-border;
$tb-kendo-listview-text: $tb-kendo-component-text;
$tb-kendo-listview-bg: $tb-kendo-component-bg;
$tb-kendo-listview-border: $tb-kendo-component-border;
$tb-kendo-loader-container-panel-border-color: $tb-kendo-component-border;
$tb-kendo-loading-bg: $tb-kendo-component-bg;
$tb-kendo-menu-popup-item-hover-bg: $tb-kendo-list-item-hover-bg;
$tb-kendo-menu-popup-item-hover-text: $tb-kendo-list-item-hover-text;
$tb-kendo-menu-popup-item-expanded-bg: $tb-kendo-list-item-selected-bg;
$tb-kendo-menu-popup-item-expanded-text: $tb-kendo-list-item-selected-text;
$tb-kendo-notification-bg: $tb-kendo-component-bg;
$tb-kendo-notification-text: $tb-kendo-component-text;
$tb-kendo-notification-border: $tb-kendo-component-border;
$tb-kendo-pager-bg: $tb-kendo-component-bg;
$tb-kendo-pager-border: $tb-kendo-component-border;
$tb-kendo-picker-bg: $tb-kendo-input-bg;
$tb-kendo-picker-text: $tb-kendo-input-text;
$tb-kendo-picker-border: $tb-kendo-input-border;
$tb-kendo-picker-hover-border: $tb-kendo-input-hover-border;
$tb-kendo-picker-focus-border: $tb-kendo-input-focus-border;
$tb-kendo-picker-disabled-bg: $tb-kendo-input-disabled-bg;
$tb-kendo-picker-disabled-text: $tb-kendo-input-disabled-text;
$tb-kendo-picker-disabled-border: $tb-kendo-input-disabled-border;
$tb-kendo-picker-outline-text: $tb-kendo-picker-text;
$tb-kendo-picker-outline-border: $tb-kendo-picker-border;
$tb-kendo-picker-outline-hover-border: $tb-kendo-picker-hover-border;
$tb-kendo-picker-outline-focus-border: $tb-kendo-picker-focus-border;
$tb-kendo-picker-flat-text: $tb-kendo-picker-text;
$tb-kendo-picker-flat-border: $tb-kendo-picker-border;
$tb-kendo-picker-flat-focus-border: $tb-kendo-picker-focus-border;
$tb-kendo-picker-flat-hover-border: $tb-kendo-picker-hover-border;
$tb-kendo-popover-text: $tb-kendo-component-text;
$tb-kendo-popover-bg: $tb-kendo-component-bg;
$tb-kendo-popover-border: $tb-kendo-component-border;
$tb-kendo-popover-header-border: $tb-kendo-card-header-border;
$tb-kendo-popup-bg: $tb-kendo-component-bg;
$tb-kendo-popup-text: $tb-kendo-component-text;
$tb-kendo-popup-border: $tb-kendo-component-border;
$tb-kendo-progressbar-text: $tb-kendo-component-text;
$tb-kendo-progressbar-indeterminate-bg: $tb-kendo-progressbar-bg;
$tb-kendo-progressbar-indeterminate-text: $tb-kendo-progressbar-text;
$tb-kendo-radio-border: $tb-kendo-checkbox-border;
$tb-kendo-radio-checked-border: $tb-kendo-radio-checked-text;
$tb-kendo-radio-disabled-border: $tb-kendo-checkbox-disabled-border;
$tb-kendo-radio-disabled-checked-text: $tb-kendo-radio-disabled-border;
$tb-kendo-radio-disabled-checked-border: $tb-kendo-radio-disabled-border;
$tb-kendo-radio-invalid-text: $tb-kendo-checkbox-invalid-text;
$tb-kendo-radio-invalid-border: $tb-kendo-checkbox-invalid-border;
$tb-kendo-scrollview-text: $tb-kendo-component-text;
$tb-kendo-scrollview-bg: $tb-kendo-component-bg;
$tb-kendo-scrollview-border: $tb-kendo-component-border;
$tb-kendo-scrollview-pagebutton-bg: $tb-kendo-button-bg;
$tb-kendo-scrollview-pagebutton-border: $tb-kendo-button-border;
$tb-kendo-switch-on-thumb-bg: $tb-kendo-base-text;
$tb-kendo-table-bg: $tb-kendo-component-bg;
$tb-kendo-table-text: $tb-kendo-component-text;
$tb-kendo-table-border: $tb-kendo-component-border;
$tb-kendo-table-header-bg: $tb-kendo-table-bg;
$tb-kendo-table-header-border: $tb-kendo-table-border;
$tb-kendo-table-footer-bg: $tb-kendo-table-header-bg;
$tb-kendo-table-footer-text: $tb-kendo-table-header-text;
$tb-kendo-table-footer-border: $tb-kendo-table-header-border;
$tb-kendo-table-group-row-bg: $tb-kendo-table-header-bg;
$tb-kendo-table-group-row-text: $tb-kendo-table-header-text;
$tb-kendo-table-group-row-border: $tb-kendo-table-header-border;
$tb-kendo-table-hover-border: $tb-kendo-table-border;
$tb-kendo-tabstrip-border: $tb-kendo-component-border;
$tb-kendo-tabstrip-item-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-tabstrip-content-focus-border: $tb-kendo-component-text;
$tb-kendo-tile-layout-hint-border: $tb-kendo-component-border;
$tb-kendo-toolbar-bg: $tb-kendo-component-bg;
$tb-kendo-toolbar-text: $tb-kendo-component-text;
$tb-kendo-toolbar-separator-border: $tb-kendo-component-border;
$tb-kendo-tooltip-border: $tb-kendo-tooltip-bg;
$tb-kendo-treeview-text: $tb-kendo-component-text;
$tb-kendo-treeview-item-selected-text: $tb-kendo-selected-bg;
$tb-kendo-upload-text: $tb-kendo-component-text;
$tb-kendo-upload-bg: $tb-kendo-component-bg;
$tb-kendo-upload-border: $tb-kendo-component-border;
$tb-kendo-upload-dropzone-border: $tb-kendo-upload-border;
$tb-kendo-upload-dropzone-hover-bg: $tb-kendo-hover-bg;
$tb-kendo-window-bg: $tb-kendo-component-bg;
$tb-kendo-window-text: $tb-kendo-component-text;
$tb-kendo-window-border: $tb-kendo-component-border;
$tb-kendo-grid-bg-2: $tb-kendo-base-bg;

$tb-box-shadow-depth-1: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-2: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-3: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-4: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-5: 0px 3px 5px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-6: 0px 5px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-7: 0px 7px 8px 0px rgba(0, 0, 0, 0.2), 0px 5px 22px 0px rgba(0, 0, 0, 0.12), 0px 12px 17px 0px rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-8: 0px 8px 10px 0 rgba(0, 0, 0, 0.2), 0px 6px 30px 0 rgba(0, 0, 0, 0.12), 0px 16px 24px 0 rgba(0, 0, 0, 0.14);
$tb-box-shadow-depth-9: 0px 11px 15px 0 rgba(0, 0, 0, 0.2), 0px 9px 46px 0 rgba(0, 0, 0, 0.12), 0px 24px 38px 0 rgba(0, 0, 0, 0.14);

$adjust-contrast: $tb-adjust-contrast;
$kendo-border-radius: $tb-kendo-border-radius;
$kendo-appbar-padding-x: $tb-kendo-appbar-padding-x;
$kendo-appbar-padding-y: $tb-kendo-appbar-padding-y;
$kendo-appbar-border-width: $tb-kendo-appbar-border-width;
$kendo-appbar-font-size: $tb-kendo-appbar-font-size;
$kendo-appbar-gap: $tb-kendo-appbar-gap;
$kendo-avatar-border-width: $tb-kendo-avatar-border-width;
$kendo-avatar-font-size: $tb-kendo-avatar-font-size;
$kendo-badge-border-width: $tb-kendo-badge-border-width;
$kendo-bottom-nav-padding-x: $tb-kendo-bottom-nav-padding-x;
$kendo-bottom-nav-font-size: $tb-kendo-bottom-nav-font-size;
$kendo-bottom-nav-letter-spacing: $tb-kendo-bottom-nav-letter-spacing;
$kendo-bottom-nav-item-padding-x: $tb-kendo-bottom-nav-item-padding-x;
$kendo-bottom-nav-item-min-width: $tb-kendo-bottom-nav-item-min-width;
$kendo-breadcrumb-border-width: $tb-kendo-breadcrumb-border-width;
$kendo-breadcrumb-font-size: $tb-kendo-breadcrumb-font-size;
$kendo-breadcrumb-link-padding-x: $tb-kendo-breadcrumb-link-padding-x;
$kendo-breadcrumb-link-padding-y: $tb-kendo-breadcrumb-link-padding-y;
$kendo-breadcrumb-link-border-radius: $tb-kendo-breadcrumb-link-border-radius;
$kendo-breadcrumb-icon-link-padding-y: $tb-kendo-breadcrumb-icon-link-padding-y;
$kendo-breadcrumb-link-icon-spacing: $tb-kendo-breadcrumb-link-icon-spacing;
$kendo-button-border-width: $tb-kendo-button-border-width;
$kendo-button-padding-x: $tb-kendo-button-padding-x;
$kendo-button-padding-y: $tb-kendo-button-padding-y;
$kendo-card-padding-x: $tb-kendo-card-padding-x;
$kendo-card-padding-y: $tb-kendo-card-padding-y;
$kendo-card-border-width: $tb-kendo-card-border-width;
$kendo-card-border-radius: $tb-kendo-card-border-radius;
$kendo-card-font-size: $tb-kendo-card-font-size;
$kendo-card-deck-gap: $tb-kendo-card-deck-gap;
$kendo-card-header-border-width: $tb-kendo-card-header-border-width;
$kendo-card-body-padding-x: $tb-kendo-card-body-padding-x;
$kendo-card-body-padding-y: $tb-kendo-card-body-padding-y;
$kendo-card-footer-border-width: $tb-kendo-card-footer-border-width;
$kendo-card-title-margin-bottom: $tb-kendo-card-title-margin-bottom;
$kendo-card-title-font-size: $tb-kendo-card-title-font-size;
$kendo-card-subtitle-margin-bottom: $tb-kendo-card-subtitle-margin-bottom;
$kendo-card-subtitle-font-size: $tb-kendo-card-subtitle-font-size;
$kendo-card-img-max-width: $tb-kendo-card-img-max-width;
$kendo-card-avatar-size: $tb-kendo-card-avatar-size;
$kendo-card-actions-padding-x: $tb-kendo-card-actions-padding-x;
$kendo-card-actions-padding-y: $tb-kendo-card-actions-padding-y;
$kendo-card-actions-gap: $tb-kendo-card-actions-gap;
$kendo-card-deck-scroll-button-offset: $tb-kendo-card-deck-scroll-button-offset;
$kendo-card-callout-width: $tb-kendo-card-callout-width;
$kendo-card-callout-height: $tb-kendo-card-callout-height;
$kendo-checkbox-border-width: $tb-kendo-checkbox-border-width;
$kendo-checkbox-label-margin-x: $tb-kendo-checkbox-label-margin-x;
$kendo-checkbox-list-spacing: $tb-kendo-checkbox-list-spacing;
$kendo-checkbox-list-item-padding-x: $tb-kendo-checkbox-list-item-padding-x;
$kendo-checkbox-list-item-padding-y: $tb-kendo-checkbox-list-item-padding-y;
$kendo-chip-border-width: $tb-kendo-chip-border-width;
$kendo-chip-spacing: $tb-kendo-chip-spacing;
$kendo-chip-padding-x: $tb-kendo-chip-padding-x;
$kendo-chip-padding-y: $tb-kendo-chip-padding-y;
$kendo-chip-font-size: $tb-kendo-chip-font-size;
$kendo-color-gradient-spacer: $tb-kendo-color-gradient-spacer;
$kendo-color-gradient-width: $tb-kendo-color-gradient-width;
$kendo-color-gradient-border-width: $tb-kendo-color-gradient-border-width;
$kendo-color-gradient-border-radius: $tb-kendo-color-gradient-border-radius;
$kendo-color-gradient-font-size: $tb-kendo-color-gradient-font-size;
$kendo-color-gradient-canvas-border-radius: $tb-kendo-color-gradient-canvas-border-radius;
$kendo-color-gradient-canvas-rectangle-height: $tb-kendo-color-gradient-canvas-rectangle-height;
$kendo-color-gradient-slider-track-size: $tb-kendo-color-gradient-slider-track-size;
$kendo-color-gradient-slider-border-radius: $tb-kendo-color-gradient-slider-border-radius;
$kendo-color-gradient-slider-draghandle-border-width: $tb-kendo-color-gradient-slider-draghandle-border-width;
$kendo-color-gradient-slider-vertical-size: $tb-kendo-color-gradient-slider-vertical-size;
$kendo-color-gradient-draghandle-width: $tb-kendo-color-gradient-draghandle-width;
$kendo-color-gradient-draghandle-height: $tb-kendo-color-gradient-draghandle-height;
$kendo-color-gradient-draghandle-border-width: $tb-kendo-color-gradient-draghandle-border-width;
$kendo-color-gradient-draghandle-outline-width: $tb-kendo-color-gradient-draghandle-outline-width;
$kendo-color-gradient-canvas-draghandle-margin-y: $tb-kendo-color-gradient-canvas-draghandle-margin-y;
$kendo-color-gradient-canvas-draghandle-margin-x: $tb-kendo-color-gradient-canvas-draghandle-margin-x;
$kendo-color-gradient-input-width: $tb-kendo-color-gradient-input-width;
$kendo-color-gradient-input-gap: $tb-kendo-color-gradient-input-gap;
$kendo-color-gradient-input-label-gap: $tb-kendo-color-gradient-input-label-gap;
$kendo-color-gradient-contrast-spacer: $tb-kendo-color-gradient-contrast-spacer;
$kendo-color-editor-spacer: $tb-kendo-color-editor-spacer;
$kendo-color-editor-min-width: $tb-kendo-color-editor-min-width;
$kendo-color-editor-border-width: $tb-kendo-color-editor-border-width;
$kendo-color-editor-border-radius: $tb-kendo-color-editor-border-radius;
$kendo-color-editor-font-size: $tb-kendo-color-editor-font-size;
$kendo-color-editor-header-actions-gap: $tb-kendo-color-editor-header-actions-gap;
$kendo-color-editor-color-preview-width: $tb-kendo-color-editor-color-preview-width;
$kendo-color-editor-color-preview-height: $tb-kendo-color-editor-color-preview-height;
$kendo-color-editor-preview-gap: $tb-kendo-color-editor-preview-gap;
$kendo-color-editor-color-gradient-focus-outline: $tb-kendo-color-editor-color-gradient-focus-outline;
$kendo-color-palette-font-size: $tb-kendo-color-palette-font-size;
$kendo-color-palette-tile-width: $tb-kendo-color-palette-tile-width;
$kendo-dialog-buttongroup-padding-x: $tb-kendo-dialog-buttongroup-padding-x;
$kendo-dialog-buttongroup-padding-y: $tb-kendo-dialog-buttongroup-padding-y;
$kendo-dialog-buttongroup-border-width: $tb-kendo-dialog-buttongroup-border-width;
$kendo-dialog-button-spacing: $tb-kendo-dialog-button-spacing;
$kendo-dock-manager-border-width: $tb-kendo-dock-manager-border-width;
$kendo-dock-manager-pane-header-padding-y: $tb-kendo-dock-manager-pane-header-padding-y;
$kendo-dock-manager-pane-header-padding-x: $tb-kendo-dock-manager-pane-header-padding-x;
$kendo-dock-manager-pane-title-font-size: $tb-kendo-dock-manager-pane-title-font-size;
$kendo-dock-manager-unpinned-container-width: $tb-kendo-dock-manager-unpinned-container-width;
$kendo-dock-indicator-padding: $tb-kendo-dock-indicator-padding;
$kendo-dock-indicator-outline-width: $tb-kendo-dock-indicator-outline-width;
$kendo-dock-manager-dock-preview-border-width: $tb-kendo-dock-manager-dock-preview-border-width;
$kendo-dock-manager-dock-preview-border-radius: $tb-kendo-dock-manager-dock-preview-border-radius;
$kendo-dropdowntree-popup-padding-x: $tb-kendo-dropdowntree-popup-padding-x;
$kendo-dropdowntree-popup-padding-y: $tb-kendo-dropdowntree-popup-padding-y;
$kendo-dropzone-padding-x: $tb-kendo-dropzone-padding-x;
$kendo-dropzone-padding-y: $tb-kendo-dropzone-padding-y;
$kendo-dropzone-border-width: $tb-kendo-dropzone-border-width;
$kendo-dropzone-min-height: $tb-kendo-dropzone-min-height;
$kendo-dropzone-font-size: $tb-kendo-dropzone-font-size;
$kendo-dropzone-icon-spacing: $tb-kendo-dropzone-icon-spacing;
$kendo-dropzone-hint-spacing: $tb-kendo-dropzone-hint-spacing;
$kendo-dropzone-note-font-size: $tb-kendo-dropzone-note-font-size;
$kendo-editor-border-width: $tb-kendo-editor-border-width;
$kendo-editor-font-size: $tb-kendo-editor-font-size;
$kendo-editor-resize-handle-size: $tb-kendo-editor-resize-handle-size;
$kendo-editor-resize-handle-border-width: $tb-kendo-editor-resize-handle-border-width;
$kendo-editor-selectednode-outline-width: $tb-kendo-editor-selectednode-outline-width;
$kendo-expander-spacing-y: $tb-kendo-expander-spacing-y;
$kendo-expander-border-width: $tb-kendo-expander-border-width;
$kendo-expander-font-size: $tb-kendo-expander-font-size;
$kendo-expander-header-padding-x: $tb-kendo-expander-header-padding-x;
$kendo-expander-header-padding-y: $tb-kendo-expander-header-padding-y;
$kendo-expander-indicator-margin-x: $tb-kendo-expander-indicator-margin-x;
$kendo-expander-content-padding-x: $tb-kendo-expander-content-padding-x;
$kendo-expander-content-padding-y: $tb-kendo-expander-content-padding-y;
$kendo-filter-padding-x: $tb-kendo-filter-padding-x;
$kendo-filter-line-size: $tb-kendo-filter-line-size;
$kendo-fab-font-size: $tb-kendo-fab-font-size;
$kendo-fab-padding-x: $tb-kendo-fab-padding-x;
$kendo-fab-icon-padding-x: $tb-kendo-fab-icon-padding-x;
$kendo-fab-icon-spacing: $tb-kendo-fab-icon-spacing;
$kendo-fab-items-padding-x: $tb-kendo-fab-items-padding-x;
$kendo-fab-items-padding-y: $tb-kendo-fab-items-padding-y;
$kendo-fab-item-text-padding-x: $tb-kendo-fab-item-text-padding-x;
$kendo-fab-item-text-border-width: $tb-kendo-fab-item-text-border-width;
$kendo-fab-item-text-border-radius: $tb-kendo-fab-item-text-border-radius;
$kendo-fab-item-text-font-size: $tb-kendo-fab-item-text-font-size;
$kendo-fab-item-icon-padding-x: $tb-kendo-fab-item-icon-padding-x;
$kendo-floating-label-font-size: $tb-kendo-floating-label-font-size;
$kendo-form-spacer: $tb-kendo-form-spacer;
$kendo-form-font-size: $tb-kendo-form-font-size;
$kendo-form-fieldset-padding: $tb-kendo-form-fieldset-padding;
$kendo-form-legend-padding: $tb-kendo-form-legend-padding;
$kendo-form-legend-font-size: $tb-kendo-form-legend-font-size;
$kendo-form-label-margin-bottom: $tb-kendo-form-label-margin-bottom;
$kendo-form-hint-font-size: $tb-kendo-form-hint-font-size;
$kendo-form-hint-margin-top: $tb-kendo-form-hint-margin-top;
$kendo-horizontal-form-label-padding-top: $tb-kendo-horizontal-form-label-padding-top;
$kendo-horizontal-form-label-margin-x: $tb-kendo-horizontal-form-label-margin-x;
$kendo-label-optional-margin-x: $tb-kendo-label-optional-margin-x;
$kendo-label-optional-font-size: $tb-kendo-label-optional-font-size;
$kendo-fieldset-margin: $tb-kendo-fieldset-margin;
$kendo-grid-padding-x: $tb-kendo-grid-padding-x;
$kendo-grid-padding-y: $tb-kendo-grid-padding-y;
$kendo-grid-header-padding-y: $tb-kendo-grid-header-padding-y;
$kendo-grid-grouping-header-padding-x: $tb-kendo-grid-grouping-header-padding-x;
$kendo-grid-edit-cell-padding-y: $tb-kendo-grid-edit-cell-padding-y;
$kendo-grid-selection-aggregates-border-width: $tb-kendo-grid-selection-aggregates-border-width;
$kendo-grid-selection-aggregates-spacing: $tb-kendo-grid-selection-aggregates-spacing;
$kendo-grid-selection-aggregates-line-height: $tb-kendo-grid-selection-aggregates-line-height;
$kendo-grid-row-resizer-height: $tb-kendo-grid-row-resizer-height;
$kendo-input-border-width: $tb-kendo-input-border-width;
$kendo-input-padding-x: $tb-kendo-input-padding-x;
$kendo-input-padding-y: $tb-kendo-input-padding-y;
$kendo-input-font-size: $tb-kendo-input-font-size;
$kendo-listbox-spacing: $tb-kendo-listbox-spacing;
$kendo-listbox-button-spacing: $tb-kendo-listbox-button-spacing;
$kendo-listbox-default-height: $tb-kendo-listbox-default-height;
$kendo-listbox-border-width: $tb-kendo-listbox-border-width;
$kendo-listbox-font-size: $tb-kendo-listbox-font-size;
$kendo-listbox-item-padding-x: $tb-kendo-listbox-item-padding-x;
$kendo-listbox-item-padding-y: $tb-kendo-listbox-item-padding-y;
$kendo-listbox-drop-hint-width: $tb-kendo-listbox-drop-hint-width;
$kendo-listview-padding-x: $tb-kendo-listview-padding-x;
$kendo-listview-padding-y: $tb-kendo-listview-padding-y;
$kendo-listview-border-width: $tb-kendo-listview-border-width;
$kendo-listview-font-size: $tb-kendo-listview-font-size;
$kendo-listview-item-padding-x: $tb-kendo-listview-item-padding-x;
$kendo-listview-item-padding-y: $tb-kendo-listview-item-padding-y;
$kendo-loader-container-panel-border-width: $tb-kendo-loader-container-panel-border-width;
$kendo-loader-container-panel-border-radius: $tb-kendo-loader-container-panel-border-radius;
$kendo-menu-popup-border-width: $tb-kendo-menu-popup-border-width;
$kendo-menu-popup-item-padding-x: $tb-kendo-menu-popup-item-padding-x;
$kendo-menu-popup-item-padding-y: $tb-kendo-menu-popup-item-padding-y;
$kendo-menu-popup-item-spacing: $tb-kendo-menu-popup-item-spacing;
$kendo-menu-button-arrow-padding-x: $tb-kendo-menu-button-arrow-padding-x;
$kendo-notification-group-gap: $tb-kendo-notification-group-gap;
$kendo-notification-padding-x: $tb-kendo-notification-padding-x;
$kendo-notification-padding-y: $tb-kendo-notification-padding-y;
$kendo-notification-border-width: $tb-kendo-notification-border-width;
$kendo-notification-border-radius: $tb-kendo-notification-border-radius;
$kendo-notification-font-size: $tb-kendo-notification-font-size;
$kendo-notification-icon-spacing: $tb-kendo-notification-icon-spacing;
$kendo-pager-border-width: $tb-kendo-pager-border-width;
$kendo-pager-font-size: $tb-kendo-pager-font-size;
$kendo-pager-item-spacing: $tb-kendo-pager-item-spacing;
$kendo-popover-border-width: $tb-kendo-popover-border-width;
$kendo-popup-border-width: $tb-kendo-popup-border-width;
$kendo-popup-font-size: $tb-kendo-popup-font-size;
$kendo-popup-content-padding-x: $tb-kendo-popup-content-padding-x;
$kendo-popup-content-padding-y: $tb-kendo-popup-content-padding-y;
$kendo-progressbar-height: $tb-kendo-progressbar-height;
$kendo-progressbar-border-width: $tb-kendo-progressbar-border-width;
$kendo-progressbar-font-size: $tb-kendo-progressbar-font-size;
$kendo-radio-border-width: $tb-kendo-radio-border-width;
$kendo-radio-label-margin-x: $tb-kendo-radio-label-margin-x;
$kendo-radio-list-spacing: $tb-kendo-radio-list-spacing;
$kendo-radio-list-item-padding-x: $tb-kendo-radio-list-item-padding-x;
$kendo-radio-list-item-padding-y: $tb-kendo-radio-list-item-padding-y;
$kendo-rating-font-size: $tb-kendo-rating-font-size;
$kendo-rating-container-margin-x: $tb-kendo-rating-container-margin-x;
$kendo-rating-item-padding-x: $tb-kendo-rating-item-padding-x;
$kendo-rating-item-padding-y: $tb-kendo-rating-item-padding-y;
$kendo-rating-label-margin-x: $tb-kendo-rating-label-margin-x;
$kendo-rating-label-margin-y: $tb-kendo-rating-label-margin-y;
$kendo-scrollview-border-width: $tb-kendo-scrollview-border-width;
$kendo-scrollview-font-size: $tb-kendo-scrollview-font-size;
$kendo-scrollview-pagebutton-size: $tb-kendo-scrollview-pagebutton-size;
$kendo-scrollview-pager-item-spacing: $tb-kendo-scrollview-pager-item-spacing;
$kendo-scrollview-pager-item-border-width: $tb-kendo-scrollview-pager-item-border-width;
$kendo-skeleton-text-border-radius: $tb-kendo-skeleton-text-border-radius;
$kendo-skeleton-circle-border-radius: $tb-kendo-skeleton-circle-border-radius;
$kendo-split-button-arrow-padding-x: $tb-kendo-split-button-arrow-padding-x;
$kendo-table-border-width: $tb-kendo-table-border-width;
$kendo-table-cell-vertical-border-width: $tb-kendo-table-cell-vertical-border-width;
$kendo-table-cell-horizontal-border-width: $tb-kendo-table-cell-horizontal-border-width;
$kendo-tabstrip-item-padding-x: $tb-kendo-tabstrip-item-padding-x;
$kendo-tabstrip-item-padding-y: $tb-kendo-tabstrip-item-padding-y;
$kendo-tabstrip-item-border-width: $tb-kendo-tabstrip-item-border-width;
$kendo-tabstrip-item-border-radius: $tb-kendo-tabstrip-item-border-radius;
$kendo-tabstrip-item-gap: $tb-kendo-tabstrip-item-gap;
$kendo-tabstrip-content-padding-x: $tb-kendo-tabstrip-content-padding-x;
$kendo-tabstrip-content-padding-y: $tb-kendo-tabstrip-content-padding-y;
$kendo-tabstrip-content-border-width: $tb-kendo-tabstrip-content-border-width;
$kendo-tile-layout-border-width: $tb-kendo-tile-layout-border-width;
$kendo-tile-layout-padding-x: $tb-kendo-tile-layout-padding-x;
$kendo-tile-layout-card-border-width: $tb-kendo-tile-layout-card-border-width;
$kendo-tile-layout-hint-border-width: $tb-kendo-tile-layout-hint-border-width;
$kendo-tile-layout-hint-border-radius: $tb-kendo-tile-layout-hint-border-radius;
$kendo-toolbar-font-size: $tb-kendo-toolbar-font-size;
$kendo-toolbar-flat-border-width: $tb-kendo-toolbar-flat-border-width;
$kendo-tooltip-padding-y: $tb-kendo-tooltip-padding-y;
$kendo-tooltip-padding-x: $tb-kendo-tooltip-padding-x;
$kendo-tooltip-border-width: $tb-kendo-tooltip-border-width;
$kendo-tooltip-border-radius: $tb-kendo-tooltip-border-radius;
$kendo-tooltip-font-size: $tb-kendo-tooltip-font-size;
$kendo-tooltip-title-font-size: $tb-kendo-tooltip-title-font-size;
$kendo-tooltip-callout-size: $tb-kendo-tooltip-callout-size;
$kendo-treeview-font-size: $tb-kendo-treeview-font-size;
$kendo-treeview-indent: $tb-kendo-treeview-indent;
$kendo-treeview-item-padding-x: $tb-kendo-treeview-item-padding-x;
$kendo-treeview-item-padding-y: $tb-kendo-treeview-item-padding-y;
$kendo-treeview-item-border-width: $tb-kendo-treeview-item-border-width;
$kendo-upload-border-width: $tb-kendo-upload-border-width;
$kendo-upload-font-size: $tb-kendo-upload-font-size;
$kendo-upload-max-height: $tb-kendo-upload-max-height;
$kendo-upload-dropzone-padding-x: $tb-kendo-upload-dropzone-padding-x;
$kendo-upload-dropzone-padding-y: $tb-kendo-upload-dropzone-padding-y;
$kendo-upload-item-padding-x: $tb-kendo-upload-item-padding-x;
$kendo-upload-multiple-items-spacing: $tb-kendo-upload-multiple-items-spacing;
$kendo-upload-validation-font-size: $tb-kendo-upload-validation-font-size;
$kendo-upload-icon-spacing: $tb-kendo-upload-icon-spacing;
$kendo-window-border-width: $tb-kendo-window-border-width;
$kendo-window-border-radius: $tb-kendo-window-border-radius;
$kendo-window-font-size: $tb-kendo-window-font-size;
$kendo-window-titlebar-padding-x: $tb-kendo-window-titlebar-padding-x;
$kendo-window-titlebar-padding-y: $tb-kendo-window-titlebar-padding-y;
$kendo-window-titlebar-border-width: $tb-kendo-window-titlebar-border-width;
$kendo-window-title-font-size: $tb-kendo-window-title-font-size;
$kendo-window-inner-padding-x: $tb-kendo-window-inner-padding-x;
$kendo-window-inner-padding-y: $tb-kendo-window-inner-padding-y;
$kendo-window-buttongroup-padding-x: $tb-kendo-window-buttongroup-padding-x;
$kendo-window-buttongroup-padding-y: $tb-kendo-window-buttongroup-padding-y;
$kendo-window-buttongroup-border-width: $tb-kendo-window-buttongroup-border-width;
$kendo-bottom-nav-padding-y: $tb-kendo-bottom-nav-padding-y;
$kendo-bottom-nav-gap: $tb-kendo-bottom-nav-gap;
$kendo-breadcrumb-icon-link-padding-x: $tb-kendo-breadcrumb-icon-link-padding-x;
$kendo-card-header-padding-x: $tb-kendo-card-header-padding-x;
$kendo-card-header-padding-y: $tb-kendo-card-header-padding-y;
$kendo-card-footer-padding-x: $tb-kendo-card-footer-padding-x;
$kendo-card-footer-padding-y: $tb-kendo-card-footer-padding-y;
$kendo-card-avatar-spacing: $tb-kendo-card-avatar-spacing;
$kendo-color-gradient-padding-y: $tb-kendo-color-gradient-padding-y;
$kendo-color-gradient-padding-x: $tb-kendo-color-gradient-padding-x;
$kendo-color-gradient-gap: $tb-kendo-color-gradient-gap;
$kendo-color-gradient-canvas-gap: $tb-kendo-color-gradient-canvas-gap;
$kendo-color-editor-header-padding-y: $tb-kendo-color-editor-header-padding-y;
$kendo-color-editor-header-padding-x: $tb-kendo-color-editor-header-padding-x;
$kendo-color-editor-views-padding-y: $tb-kendo-color-editor-views-padding-y;
$kendo-color-editor-views-padding-x: $tb-kendo-color-editor-views-padding-x;
$kendo-color-editor-views-gap: $tb-kendo-color-editor-views-gap;
$kendo-color-palette-tile-height: $tb-kendo-color-palette-tile-height;
$kendo-dock-manager-pane-content-padding-x: $tb-kendo-dock-manager-pane-content-padding-x;
$kendo-dock-manager-pane-content-padding-y: $tb-kendo-dock-manager-pane-content-padding-y;
$kendo-filter-padding-y: $tb-kendo-filter-padding-y;
$kendo-fab-padding-y: $tb-kendo-fab-padding-y;
$kendo-fab-icon-padding-y: $tb-kendo-fab-icon-padding-y;
$kendo-fab-item-text-padding-y: $tb-kendo-fab-item-text-padding-y;
$kendo-fab-item-icon-padding-y: $tb-kendo-fab-item-icon-padding-y;
$kendo-grid-header-padding-x: $tb-kendo-grid-header-padding-x;
$kendo-grid-grouping-header-padding-y: $tb-kendo-grid-grouping-header-padding-y;
$kendo-grid-cell-padding-x: $tb-kendo-grid-cell-padding-x;
$kendo-grid-cell-padding-y: $tb-kendo-grid-cell-padding-y;
$kendo-grid-filter-cell-padding-x: $tb-kendo-grid-filter-cell-padding-x;
$kendo-grid-filter-cell-padding-y: $tb-kendo-grid-filter-cell-padding-y;
$kendo-grid-edit-cell-padding-x: $tb-kendo-grid-edit-cell-padding-x;
$kendo-popover-border-radius: $tb-kendo-popover-border-radius;
$kendo-popover-font-size: $tb-kendo-popover-font-size;
$kendo-popover-header-padding-x: $tb-kendo-popover-header-padding-x;
$kendo-popover-header-padding-y: $tb-kendo-popover-header-padding-y;
$kendo-popover-header-border-width: $tb-kendo-popover-header-border-width;
$kendo-popover-body-padding-x: $tb-kendo-popover-body-padding-x;
$kendo-popover-body-padding-y: $tb-kendo-popover-body-padding-y;
$kendo-popover-actions-border-width: $tb-kendo-popover-actions-border-width;
$kendo-popover-callout-width: $tb-kendo-popover-callout-width;
$kendo-popover-callout-height: $tb-kendo-popover-callout-height;
$kendo-popover-callout-border-width: $tb-kendo-popover-callout-border-width;
$kendo-split-button-arrow-padding-y: $tb-kendo-split-button-arrow-padding-y;
$kendo-tile-layout-padding-y: $tb-kendo-tile-layout-padding-y;
$primary-palette-name: $tb-primary-palette-name;
$secondary-palette-name: $tb-secondary-palette-name;
$theme-type: $tb-theme-type;
$kendo-base-bg: $tb-kendo-base-bg;
$kendo-base-text: $tb-kendo-base-text;
$kendo-base-border: $tb-kendo-base-border;
$kendo-hover-bg: $tb-kendo-hover-bg;
$kendo-hover-border: $tb-kendo-hover-border;
$kendo-selected-bg: $tb-kendo-selected-bg;
$kendo-selected-text: $tb-kendo-selected-text;
$kendo-selected-border: $tb-kendo-selected-border;
$kendo-disabled-text: $tb-kendo-disabled-text;
$kendo-appbar-light-bg: $tb-kendo-appbar-light-bg;
$kendo-appbar-light-text: $tb-kendo-appbar-light-text;
$kendo-appbar-dark-bg: $tb-kendo-appbar-dark-bg;
$kendo-appbar-dark-text: $tb-kendo-appbar-dark-text;
$kendo-bottom-nav-flat-text: $tb-kendo-bottom-nav-flat-text;
$kendo-bottom-nav-flat-bg: $tb-kendo-bottom-nav-flat-bg;
$kendo-bottom-nav-flat-border: $tb-kendo-bottom-nav-flat-border;
$kendo-breadcrumb-bg: $tb-kendo-breadcrumb-bg;
$kendo-breadcrumb-text: $tb-kendo-breadcrumb-text;
$kendo-breadcrumb-border: $tb-kendo-breadcrumb-border;
$kendo-breadcrumb-link-text: $tb-kendo-breadcrumb-link-text;
$kendo-breadcrumb-link-focus-bg: $tb-kendo-breadcrumb-link-focus-bg;
$kendo-breadcrumb-root-link-focus-bg: $tb-kendo-breadcrumb-root-link-focus-bg;
$kendo-button-disabled-bg: $tb-kendo-button-disabled-bg;
$kendo-button-disabled-text: $tb-kendo-button-disabled-text;
$kendo-button-disabled-border: $tb-kendo-button-disabled-border;
$kendo-card-border: $tb-kendo-card-border;
$kendo-series-a: $tb-kendo-series-a;
$kendo-series-b: $tb-kendo-series-b;
$kendo-series-c: $tb-kendo-series-c;
$kendo-series-d: $tb-kendo-series-d;
$kendo-series-e: $tb-kendo-series-e;
$kendo-series-f: $tb-kendo-series-f;
$kendo-chart-major-lines: $tb-kendo-chart-major-lines;
$kendo-chart-minor-lines: $tb-kendo-chart-minor-lines;
$kendo-checkbox-border: $tb-kendo-checkbox-border;
$kendo-checkbox-checked-bg: $tb-kendo-checkbox-checked-bg;
$kendo-checkbox-checked-text: $tb-kendo-checkbox-checked-text;
$kendo-checkbox-disabled-border: $tb-kendo-checkbox-disabled-border;
$kendo-checkbox-disabled-checked-text: $tb-kendo-checkbox-disabled-checked-text;
$kendo-checkbox-invalid-text: $tb-kendo-checkbox-invalid-text;
$kendo-checkbox-invalid-border: $tb-kendo-checkbox-invalid-border;
$kendo-checkbox-ripple-bg: $tb-kendo-checkbox-ripple-bg;
$kendo-chip-solid-focus-bg: $tb-kendo-chip-solid-focus-bg;
$kendo-chip-solid-hover-bg: $tb-kendo-chip-solid-hover-bg;
$kendo-chip-solid-selected-bg: $tb-kendo-chip-solid-selected-bg;
$kendo-chip-outline-bg: $tb-kendo-chip-outline-bg;
$kendo-chip-outline-hover-bg: $tb-kendo-chip-outline-hover-bg;
$kendo-chip-outline-hover-text: $tb-kendo-chip-outline-hover-text;
$kendo-chip-outline-selected-bg: $tb-kendo-chip-outline-selected-bg;
$kendo-color-gradient-text: $tb-kendo-color-gradient-text;
$kendo-color-gradient-bg: $tb-kendo-color-gradient-bg;
$kendo-color-gradient-border: $tb-kendo-color-gradient-border;
$kendo-color-gradient-draghandle-bg: $tb-kendo-color-gradient-draghandle-bg;
$kendo-color-gradient-draghandle-border: $tb-kendo-color-gradient-draghandle-border;
$kendo-color-gradient-draghandle-focus-shadow: $tb-kendo-color-gradient-draghandle-focus-shadow;
$kendo-color-gradient-draghandle-hover-shadow: $tb-kendo-color-gradient-draghandle-hover-shadow;
$kendo-color-gradient-input-label-text: $tb-kendo-color-gradient-input-label-text;
$kendo-color-editor-text: $tb-kendo-color-editor-text;
$kendo-color-editor-bg: $tb-kendo-color-editor-bg;
$kendo-color-editor-border: $tb-kendo-color-editor-border;
$kendo-color-editor-color-gradient-focus-outline-color: $tb-kendo-color-editor-color-gradient-focus-outline-color;
$kendo-component-text: $tb-kendo-component-text;
$kendo-component-border: $tb-kendo-component-border;
$kendo-dock-manager-unpinned-container-bg: $tb-kendo-dock-manager-unpinned-container-bg;
$kendo-dock-indicator-text: $tb-kendo-dock-indicator-text;
$kendo-dock-indicator-hover-bg: $tb-kendo-dock-indicator-hover-bg;
$kendo-dock-indicator-hover-text: $tb-kendo-dock-indicator-hover-text;
$kendo-dock-manager-dock-preview-bg: $tb-kendo-dock-manager-dock-preview-bg;
$kendo-dock-manager-dock-preview-border: $tb-kendo-dock-manager-dock-preview-border;
$kendo-dropzone-bg: $tb-kendo-dropzone-bg;
$kendo-dropzone-icon-text: $tb-kendo-dropzone-icon-text;
$kendo-dropzone-icon-hover-text: $tb-kendo-dropzone-icon-hover-text;
$kendo-dropzone-note-text: $tb-kendo-dropzone-note-text;
$kendo-editor-placeholder-text: $tb-kendo-editor-placeholder-text;
$kendo-editor-selected-text: $tb-kendo-editor-selected-text;
$kendo-editor-selected-bg: $tb-kendo-editor-selected-bg;
$kendo-editor-highlighted-bg: $tb-kendo-editor-highlighted-bg;
$kendo-editor-resize-handle-border: $tb-kendo-editor-resize-handle-border;
$kendo-editor-resize-handle-bg: $tb-kendo-editor-resize-handle-bg;
$kendo-editor-selectednode-outline-color: $tb-kendo-editor-selectednode-outline-color;
$kendo-expander-header-bg: $tb-kendo-expander-header-bg;
$kendo-expander-header-hover-bg: $tb-kendo-expander-header-hover-bg;
$kendo-expander-header-focus-bg: $tb-kendo-expander-header-focus-bg;
$kendo-expander-title-text: $tb-kendo-expander-title-text;
$kendo-expander-header-sub-title-text: $tb-kendo-expander-header-sub-title-text;
$kendo-filter-preview-field-text: $tb-kendo-filter-preview-field-text;
$kendo-filter-preview-operator-text: $tb-kendo-filter-preview-operator-text;
$kendo-floating-label-text: $tb-kendo-floating-label-text;
$kendo-floating-label-focus-text: $tb-kendo-floating-label-focus-text;
$kendo-grid-bg: $tb-kendo-grid-bg;
$kendo-grid-text: $tb-kendo-grid-text;
$kendo-grid-border: $tb-kendo-grid-border;
$kendo-grid-header-bg: $tb-kendo-grid-header-bg;
$kendo-grid-header-text: $tb-kendo-grid-header-text;
$kendo-grid-header-border: $tb-kendo-grid-header-border;
$kendo-grid-footer-bg: $tb-kendo-grid-footer-bg;
$kendo-grid-footer-text: $tb-kendo-grid-footer-text;
$kendo-grid-footer-border: $tb-kendo-grid-footer-border;
$kendo-grid-alt-bg: $tb-kendo-grid-alt-bg;
$kendo-grid-hover-bg: $tb-kendo-grid-hover-bg;
$kendo-grid-selected-bg: $tb-kendo-grid-selected-bg;
$kendo-grid-row-resizer-hover-bg: $tb-kendo-grid-row-resizer-hover-bg;
$kendo-grid-row-resizer-active-bg: $tb-kendo-grid-row-resizer-active-bg;
$kendo-input-bg: $tb-kendo-input-bg;
$kendo-input-border: $tb-kendo-input-border;
$kendo-input-focus-border: $tb-kendo-input-focus-border;
$kendo-input-disabled-bg: $tb-kendo-input-disabled-bg;
$kendo-input-disabled-text: $tb-kendo-input-disabled-text;
$kendo-input-disabled-border: $tb-kendo-input-disabled-border;
$kendo-input-placeholder-text: $tb-kendo-input-placeholder-text;
$kendo-input-separator-text: $tb-kendo-input-separator-text;
$kendo-input-prefix-text: $tb-kendo-input-prefix-text;
$kendo-input-suffix-text: $tb-kendo-input-suffix-text;
$kendo-input-invalid-border: $tb-kendo-input-invalid-border;
$kendo-input-valid-border: $tb-kendo-input-valid-border;
$kendo-list-item-selected-text: $tb-kendo-list-item-selected-text;
$kendo-list-no-data-text: $tb-kendo-list-no-data-text;
$kendo-list-option-label-text: $tb-kendo-list-option-label-text;
$kendo-listview-item-selected-bg: $tb-kendo-listview-item-selected-bg;
$kendo-listview-item-focus-bg: $tb-kendo-listview-item-focus-bg;
$kendo-loader-secondary-bg: $tb-kendo-loader-secondary-bg;
$kendo-loader-container-panel-bg: $tb-kendo-loader-container-panel-bg;
$kendo-menu-popup-bg: $tb-kendo-menu-popup-bg;
$kendo-menu-popup-text: $tb-kendo-menu-popup-text;
$kendo-menu-popup-border: $tb-kendo-menu-popup-border;
$kendo-pager-text: $tb-kendo-pager-text;
$kendo-pager-focus-bg: $tb-kendo-pager-focus-bg;
$kendo-progressbar-bg: $tb-kendo-progressbar-bg;
$kendo-progressbar-value-bg: $tb-kendo-progressbar-value-bg;
$kendo-progressbar-value-text: $tb-kendo-progressbar-value-text;
$kendo-circular-progressbar-arc-stroke: $tb-kendo-circular-progressbar-arc-stroke;
$kendo-radio-checked-text: $tb-kendo-radio-checked-text;
$kendo-radio-ripple-bg: $tb-kendo-radio-ripple-bg;
$kendo-rating-icon-text: $tb-kendo-rating-icon-text;
$kendo-rating-icon-selected-text: $tb-kendo-rating-icon-selected-text;
$kendo-rating-icon-hover-text: $tb-kendo-rating-icon-hover-text;
$kendo-rating-icon-focus-text: $tb-kendo-rating-icon-focus-text;
$kendo-scrollview-pagebutton-primary-bg: $tb-kendo-scrollview-pagebutton-primary-bg;
$kendo-scrollview-pagebutton-primary-border: $tb-kendo-scrollview-pagebutton-primary-border;
$kendo-scrollview-navigation-color: $tb-kendo-scrollview-navigation-color;
$kendo-scrollview-navigation-bg: $tb-kendo-scrollview-navigation-bg;
$kendo-scrollview-light-bg: $tb-kendo-scrollview-light-bg;
$kendo-scrollview-dark-bg: $tb-kendo-scrollview-dark-bg;
$kendo-skeleton-item-bg: $tb-kendo-skeleton-item-bg;
$kendo-skeleton-wave-bg: $tb-kendo-skeleton-wave-bg;
$kendo-switch-off-track-bg: $tb-kendo-switch-off-track-bg;
$kendo-switch-off-thumb-bg: $tb-kendo-switch-off-thumb-bg;
$kendo-switch-on-track-bg: $tb-kendo-switch-on-track-bg;
$kendo-table-header-text: $tb-kendo-table-header-text;
$kendo-table-alt-row-bg: $tb-kendo-table-alt-row-bg;
$kendo-table-hover-bg: $tb-kendo-table-hover-bg;
$kendo-table-selected-bg: $tb-kendo-table-selected-bg;
$kendo-tabstrip-text: $tb-kendo-tabstrip-text;
$kendo-tabstrip-item-text: $tb-kendo-tabstrip-item-text;
$kendo-tabstrip-item-hover-text: $tb-kendo-tabstrip-item-hover-text;
$kendo-tabstrip-item-selected-text: $tb-kendo-tabstrip-item-selected-text;
$kendo-tabstrip-content-bg: $tb-kendo-tabstrip-content-bg;
$kendo-tile-layout-bg: $tb-kendo-tile-layout-bg;
$kendo-tile-layout-hint-bg: $tb-kendo-tile-layout-hint-bg;
$kendo-tooltip-bg: $tb-kendo-tooltip-bg;
$kendo-tooltip-text: $tb-kendo-tooltip-text;
$kendo-treeview-item-hover-bg: $tb-kendo-treeview-item-hover-bg;
$kendo-treeview-loadmore-bg: $tb-kendo-treeview-loadmore-bg;
$kendo-treeview-loadmore-hover-bg: $tb-kendo-treeview-loadmore-hover-bg;
$kendo-treeview-loadmore-focus-bg: $tb-kendo-treeview-loadmore-focus-bg;
$kendo-upload-dropzone-text: $tb-kendo-upload-dropzone-text;
$kendo-upload-dropzone-bg: $tb-kendo-upload-dropzone-bg;
$kendo-upload-status-text: $tb-kendo-upload-status-text;
$kendo-upload-icon-color: $tb-kendo-upload-icon-color;
$kendo-upload-progress-bg: $tb-kendo-upload-progress-bg;
$kendo-upload-success-text: $tb-kendo-upload-success-text;
$kendo-upload-success-bg: $tb-kendo-upload-success-bg;
$kendo-upload-error-text: $tb-kendo-upload-error-text;
$kendo-upload-error-bg: $tb-kendo-upload-error-bg;
$kendo-body-bg: $tb-kendo-body-bg;
$kendo-hover-text: $tb-kendo-hover-text;
$kendo-breadcrumb-link-hover-bg: $tb-kendo-breadcrumb-link-hover-bg;
$kendo-breadcrumb-root-link-hover-bg: $tb-kendo-breadcrumb-root-link-hover-bg;
$kendo-button-bg: $tb-kendo-button-bg;
$kendo-button-text: $tb-kendo-button-text;
$kendo-button-border: $tb-kendo-button-border;
$kendo-card-bg: $tb-kendo-card-bg;
$kendo-card-text: $tb-kendo-card-text;
$kendo-card-header-border: $tb-kendo-card-header-border;
$kendo-card-footer-border: $tb-kendo-card-footer-border;
$kendo-card-subtitle-text: $tb-kendo-card-subtitle-text;
$kendo-series-1: $tb-kendo-series-1;
$kendo-checkbox-checked-border: $tb-kendo-checkbox-checked-border;
$kendo-checkbox-indeterminate-bg: $tb-kendo-checkbox-indeterminate-bg;
$kendo-checkbox-indeterminate-text: $tb-kendo-checkbox-indeterminate-text;
$kendo-checkbox-indeterminate-border: $tb-kendo-checkbox-indeterminate-border;
$kendo-checkbox-disabled-checked-bg: $tb-kendo-checkbox-disabled-checked-bg;
$kendo-checkbox-disabled-checked-border: $tb-kendo-checkbox-disabled-checked-border;
$kendo-chip-solid-bg: $tb-kendo-chip-solid-bg;
$kendo-chip-solid-text: $tb-kendo-chip-solid-text;
$kendo-chip-solid-border: $tb-kendo-chip-solid-border;
$kendo-chip-outline-text: $tb-kendo-chip-outline-text;
$kendo-chip-outline-border: $tb-kendo-chip-outline-border;
$kendo-chip-outline-selected-text: $tb-kendo-chip-outline-selected-text;
$kendo-color-gradient-focus-border: $tb-kendo-color-gradient-focus-border;
$kendo-color-editor-focus-border: $tb-kendo-color-editor-focus-border;
$kendo-component-bg: $tb-kendo-component-bg;
$kendo-dock-manager-border: $tb-kendo-dock-manager-border;
$kendo-dock-manager-pane-header-bg: $tb-kendo-dock-manager-pane-header-bg;
$kendo-dock-indicator-bg: $tb-kendo-dock-indicator-bg;
$kendo-dock-indicator-outline: $tb-kendo-dock-indicator-outline;
$kendo-dropzone-text: $tb-kendo-dropzone-text;
$kendo-dropzone-border: $tb-kendo-dropzone-border;
$kendo-expander-text: $tb-kendo-expander-text;
$kendo-expander-bg: $tb-kendo-expander-bg;
$kendo-expander-border: $tb-kendo-expander-border;
$kendo-expander-header-text: $tb-kendo-expander-header-text;
$kendo-fab-item-text: $tb-kendo-fab-item-text;
$kendo-fab-item-bg: $tb-kendo-fab-item-bg;
$kendo-fab-item-border: $tb-kendo-fab-item-border;
$kendo-fab-item-icon-text: $tb-kendo-fab-item-icon-text;
$kendo-fab-item-icon-bg: $tb-kendo-fab-item-icon-bg;
$kendo-fab-item-icon-border: $tb-kendo-fab-item-icon-border;
$kendo-form-legend-border-color: $tb-kendo-form-legend-border-color;
$kendo-form-separator-border-color: $tb-kendo-form-separator-border-color;
$kendo-grid-selection-aggregates-bg: $tb-kendo-grid-selection-aggregates-bg;
$kendo-grid-selection-aggregates-text: $tb-kendo-grid-selection-aggregates-text;
$kendo-grid-selection-aggregates-border: $tb-kendo-grid-selection-aggregates-border;
$kendo-input-text: $tb-kendo-input-text;
$kendo-input-hover-border: $tb-kendo-input-hover-border;
$kendo-input-outline-text: $tb-kendo-input-outline-text;
$kendo-input-outline-border: $tb-kendo-input-outline-border;
$kendo-input-outline-hover-border: $tb-kendo-input-outline-hover-border;
$kendo-input-outline-focus-border: $tb-kendo-input-outline-focus-border;
$kendo-input-flat-text: $tb-kendo-input-flat-text;
$kendo-input-flat-border: $tb-kendo-input-flat-border;
$kendo-input-flat-hover-border: $tb-kendo-input-flat-hover-border;
$kendo-input-flat-focus-border: $tb-kendo-input-flat-focus-border;
$kendo-list-bg: $tb-kendo-list-bg;
$kendo-list-text: $tb-kendo-list-text;
$kendo-list-border: $tb-kendo-list-border;
$kendo-list-item-hover-bg: $tb-kendo-list-item-hover-bg;
$kendo-list-item-hover-text: $tb-kendo-list-item-hover-text;
$kendo-list-item-focus-bg: $tb-kendo-list-item-focus-bg;
$kendo-list-item-selected-bg: $tb-kendo-list-item-selected-bg;
$kendo-listbox-text: $tb-kendo-listbox-text;
$kendo-listbox-bg: $tb-kendo-listbox-bg;
$kendo-listbox-border: $tb-kendo-listbox-border;
$kendo-listview-text: $tb-kendo-listview-text;
$kendo-listview-bg: $tb-kendo-listview-bg;
$kendo-listview-border: $tb-kendo-listview-border;
$kendo-loader-container-panel-border-color: $tb-kendo-loader-container-panel-border-color;
$kendo-loading-bg: $tb-kendo-loading-bg;
$kendo-menu-popup-item-hover-bg: $tb-kendo-menu-popup-item-hover-bg;
$kendo-menu-popup-item-hover-text: $tb-kendo-menu-popup-item-hover-text;
$kendo-menu-popup-item-expanded-bg: $tb-kendo-menu-popup-item-expanded-bg;
$kendo-menu-popup-item-expanded-text: $tb-kendo-menu-popup-item-expanded-text;
$kendo-notification-bg: $tb-kendo-notification-bg;
$kendo-notification-text: $tb-kendo-notification-text;
$kendo-notification-border: $tb-kendo-notification-border;
$kendo-pager-bg: $tb-kendo-pager-bg;
$kendo-pager-border: $tb-kendo-pager-border;
$kendo-picker-bg: $tb-kendo-picker-bg;
$kendo-picker-text: $tb-kendo-picker-text;
$kendo-picker-border: $tb-kendo-picker-border;
$kendo-picker-hover-border: $tb-kendo-picker-hover-border;
$kendo-picker-focus-border: $tb-kendo-picker-focus-border;
$kendo-picker-disabled-bg: $tb-kendo-picker-disabled-bg;
$kendo-picker-disabled-text: $tb-kendo-picker-disabled-text;
$kendo-picker-disabled-border: $tb-kendo-picker-disabled-border;
$kendo-picker-outline-text: $tb-kendo-picker-outline-text;
$kendo-picker-outline-border: $tb-kendo-picker-outline-border;
$kendo-picker-outline-hover-border: $tb-kendo-picker-outline-hover-border;
$kendo-picker-outline-focus-border: $tb-kendo-picker-outline-focus-border;
$kendo-picker-flat-text: $tb-kendo-picker-flat-text;
$kendo-picker-flat-border: $tb-kendo-picker-flat-border;
$kendo-picker-flat-focus-border: $tb-kendo-picker-flat-focus-border;
$kendo-picker-flat-hover-border: $tb-kendo-picker-flat-hover-border;
$kendo-popover-text: $tb-kendo-popover-text;
$kendo-popover-bg: $tb-kendo-popover-bg;
$kendo-popover-border: $tb-kendo-popover-border;
$kendo-popover-header-border: $tb-kendo-popover-header-border;
$kendo-popup-bg: $tb-kendo-popup-bg;
$kendo-popup-text: $tb-kendo-popup-text;
$kendo-popup-border: $tb-kendo-popup-border;
$kendo-progressbar-text: $tb-kendo-progressbar-text;
$kendo-progressbar-indeterminate-bg: $tb-kendo-progressbar-indeterminate-bg;
$kendo-progressbar-indeterminate-text: $tb-kendo-progressbar-indeterminate-text;
$kendo-radio-border: $tb-kendo-radio-border;
$kendo-radio-checked-border: $tb-kendo-radio-checked-border;
$kendo-radio-disabled-border: $tb-kendo-radio-disabled-border;
$kendo-radio-disabled-checked-text: $tb-kendo-radio-disabled-checked-text;
$kendo-radio-disabled-checked-border: $tb-kendo-radio-disabled-checked-border;
$kendo-radio-invalid-text: $tb-kendo-radio-invalid-text;
$kendo-radio-invalid-border: $tb-kendo-radio-invalid-border;
$kendo-scrollview-text: $tb-kendo-scrollview-text;
$kendo-scrollview-bg: $tb-kendo-scrollview-bg;
$kendo-scrollview-border: $tb-kendo-scrollview-border;
$kendo-scrollview-pagebutton-bg: $tb-kendo-scrollview-pagebutton-bg;
$kendo-scrollview-pagebutton-border: $tb-kendo-scrollview-pagebutton-border;
$kendo-switch-on-thumb-bg: $tb-kendo-switch-on-thumb-bg;
$kendo-table-bg: $tb-kendo-table-bg;
$kendo-table-text: $tb-kendo-table-text;
$kendo-table-border: $tb-kendo-table-border;
$kendo-table-header-bg: $tb-kendo-table-header-bg;
$kendo-table-header-border: $tb-kendo-table-header-border;
$kendo-table-footer-bg: $tb-kendo-table-footer-bg;
$kendo-table-footer-text: $tb-kendo-table-footer-text;
$kendo-table-footer-border: $tb-kendo-table-footer-border;
$kendo-table-group-row-bg: $tb-kendo-table-group-row-bg;
$kendo-table-group-row-text: $tb-kendo-table-group-row-text;
$kendo-table-group-row-border: $tb-kendo-table-group-row-border;
$kendo-table-hover-border: $tb-kendo-table-hover-border;
$kendo-tabstrip-border: $tb-kendo-tabstrip-border;
$kendo-tabstrip-item-hover-bg: $tb-kendo-tabstrip-item-hover-bg;
$kendo-tabstrip-content-focus-border: $tb-kendo-tabstrip-content-focus-border;
$kendo-tile-layout-hint-border: $tb-kendo-tile-layout-hint-border;
$kendo-toolbar-bg: $tb-kendo-toolbar-bg;
$kendo-toolbar-text: $tb-kendo-toolbar-text;
$kendo-toolbar-separator-border: $tb-kendo-toolbar-separator-border;
$kendo-tooltip-border: $tb-kendo-tooltip-border;
$kendo-treeview-text: $tb-kendo-treeview-text;
$kendo-treeview-item-selected-text: $tb-kendo-treeview-item-selected-text;
$kendo-upload-text: $tb-kendo-upload-text;
$kendo-upload-bg: $tb-kendo-upload-bg;
$kendo-upload-border: $tb-kendo-upload-border;
$kendo-upload-dropzone-border: $tb-kendo-upload-dropzone-border;
$kendo-upload-dropzone-hover-bg: $tb-kendo-upload-dropzone-hover-bg;
$kendo-window-bg: $tb-kendo-window-bg;
$kendo-window-text: $tb-kendo-window-text;
$kendo-window-border: $tb-kendo-window-border;
$box-shadow-depth-1: $tb-box-shadow-depth-1;
$box-shadow-depth-2: $tb-box-shadow-depth-2;
$box-shadow-depth-3: $tb-box-shadow-depth-3;
$box-shadow-depth-4: $tb-box-shadow-depth-4;
$box-shadow-depth-5: $tb-box-shadow-depth-5;
$box-shadow-depth-6: $tb-box-shadow-depth-6;
$box-shadow-depth-7: $tb-box-shadow-depth-7;
$box-shadow-depth-8: $tb-box-shadow-depth-8;
$box-shadow-depth-9: $tb-box-shadow-depth-9;
    $kendo-font-family: Roboto, "Helvetica Neue", sans-serif;
    $kendo-font-size: 14px;
    $kendo-font-weight-normal: 400;
    $kendo-line-height: 2;
    
$enable-gradients: true;

$tb-typography: (
  kendo-default-typography: (
      font-family: 'Roboto, "Helvetica Neue", sans-serif',
      font-size: 14px,
      font-weight: 400,
      line-height: 2,
    ),
  Alt-typeface: (
      font-family: 'Poppins-Regular',
      font-size: 14px,
      font-weight: 400,
      line-height: 2,
        letter-spacing: unset,
    ),
  Monospace-typeface: (
      font-family: 'Consolas',
      font-size: 14px,
      font-weight: 200,
      line-height: 2,
    ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}

$tb-effects: (
  tb-internal-none-effects: (
      box-shadow: (none), filter: (none), backdrop-filter: (none),  ),
  box-shadow-depth-1: (
      box-shadow: (0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-2: (
      box-shadow: (0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-3: (
      box-shadow: (0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-4: (
      box-shadow: (0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-5: (
      box-shadow: (0px 3px 5px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-6: (
      box-shadow: (0px 5px 5px 0px rgba(0, 0, 0, 0.2), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 8px 10px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-7: (
      box-shadow: (0px 7px 8px 0px rgba(0, 0, 0, 0.2), 0px 5px 22px 0px rgba(0, 0, 0, 0.12), 0px 12px 17px 0px rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-8: (
      box-shadow: (0px 8px 10px 0 rgba(0, 0, 0, 0.2), 0px 6px 30px 0 rgba(0, 0, 0, 0.12), 0px 16px 24px 0 rgba(0, 0, 0, 0.14)),  ),
  box-shadow-depth-9: (
      box-shadow: (0px 11px 15px 0 rgba(0, 0, 0, 0.2), 0px 9px 46px 0 rgba(0, 0, 0, 0.12), 0px 24px 38px 0 rgba(0, 0, 0, 0.14)),  ),
);

@mixin effects-classes ($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    } &-#{$selector}-important {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue !important;
      }
    }
  }
}