@import "themes/tokens";

@import "@progress/kendo-theme-material/dist/all";

@font-face {
  font-family: "Poppins";
  src: url("../public/Poppins-Regular.ttf") format("truetype");
}

$fs-body-bg: #070d19;
$fs-color-white: white;
$fs-body-bg-darker: darken($kendo-body-bg, 10%);
$fs-body-bg-lighter: lighten($kendo-body-bg, 10%);
$fs-body-bg-lighter-more: lighten($kendo-body-bg, 20%);
$fs-body-bg-highlight: yellow;

$fs-body-text: $kendo-body-text;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $fs-body-bg;
  color: $fs-body-text;
}

body {
  flex-direction: column;
}

.TrustedFilterApp {
  min-height: calc(100vh - 78px);
  margin-bottom: -30px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

// Other items

.fs-debug-output-panel {
  background-color: $fs-body-bg-highlight;
  color: black;
  text-align: center;
}

// Application root styling

.app-header {
  background-color: $fs-body-bg-darker;
}
.app-grid {
  min-height: 95vh;
}
.app-body {
  min-height: 95vh;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.app-main {
  background-color: $fs-body-bg;
}

.app-login {
  margin: 15vh 7vw;
  padding: 5px;
  width: 60%;
}
.app-section {
  display: flex;
  flex-direction: row;
}
.app-section h1 {
  transform: rotate(90deg);
}
.app-link {
}
.app-pane {
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

//Dashboard images
.fs-dash-container {
  color: $tb-kendo-base-text;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fs-hero {
  width: 95%;
}

.fs-hero img {
  margin-top: 20px;
  width: 100px;
  float: left;
  margin-right: 30px;
  margin-left: 2.2em;
}

.fs-hero h2 {
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  flex: 0 0 35%;
}
.fs-dash-row {
  flex-grow: 2;
  width: 95%;
  margin-left: 40px;
}

.fs-dash-row h4 {
  font-size: 1.33em;
}

.fs-dash-section {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: center;
}
.fs-dash-section img {
  flex: 1;
  height: 120px;
  padding: 20px;
}
.fs-dash-section p {
  font-size: 14px;
}

/* Navigation */
.RadMenu .menu-dashboard > .rmLink.rmRootLink {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.RadMenu .menu-dashboard > .rmLink.rmRootLink:before {
  content: "\f290";
}

.app-nav {
  font-size: 1.2em;
}
.app-nav .k-menu-link {
  padding: 10px 24px !important;
  line-height: 1.4285714286;
  width: 100%;
  background-color: rgb(12, 20, 39);
}

.app-nav .k-menu-item.active {
  background: none;
  color: #6571ff;
}

.app-nav path .k-svg-icon {
  height: 16px;
  width: 16px;
}

.app-nav ul {
  list-style-type: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  text-decoration: none;
  top: 10px;
}

.app-nav li:hover {
  cursor: pointer;
  opacity: 0.8;
}

.app-nav ul li {
  font-size: 16px;
  line-height: 1.4285714286;
}


.k-grid-header .k-table-row {
  background-color: $fs-body-bg-darker;
  color: $fs-body-text;
}

.fs-role-selection {
  color: $fs-body-text !important;
  font-size: 1.2em !important;
  margin-top: 5px;
  padding: 5px;
}

/* Package Cards */

.fs-package-cards {
  display: flex;
  flex-direction: row;
  width: 500;
}
.fs-package-card {
  flex: 1;
  font-size: 1.3em;
  width: 80px;
}
.fs-package-card .k-card-title,
.fs-package-card .k-card-subtitle {
  font-size: 1em;
  text-align: center;
  font-weight: bold;
}
.fs-package-card-image {
  background-color: transparent;
}
.fs-package-card img {
  align-self: center;
  width: 138px;
  height: 140px;
  padding: 5px;
  border-radius: 4px;
  margin: 5px;
}
.fs-package-card-body {
  z-index: 1;
  font-size: 14px;
  text-align: justify;
}
.fs-package-card-body {
  z-index: 1;
  font-size: 14px;
  text-align: justify;
}

/* App Bar top */

.fs-appbar.k-appbar {
  height: 3.3em;
  padding-block: 0px;
  padding-inline: 0px;
  line-height: 1em;
  padding-left: 10px;
}
.fs-appbar.k-appbar-section {
  padding-left: 10px;
  margin: 5px;
}
.fs-appbar.k-appbar-section img {
  height: 25px;
}

/* Dialogs */
.fs-dialog-edit-user .k-dialog {
  width: 500px;
}
.fs-dialog-edit-user .k-dialog form {
  width: 450px;
}

/* Dashboard */
.fs-dashboard-admin {
  background-color: red;
}

/* MISC Styling */

.card-header {
  cursor: pointer;
}

.card-header-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chevron-icon {
  margin-left: auto;
  transition: transform 0.3s;
}

.chevron-icon.expanded {
  transform: rotate(180deg);
}

.hidden {
  display: none;
}

.line-breaks {
  white-space: pre-line;
}
.k-grid-table {
  color: $fs-body-bg;
}
.k-flex-30 {
  flex: 1 1 30%;
}
.k-flex-40 {
  flex: 1 1 40%;
}
.k-card-cover {
  object-fit: cover;
  width: 100%;
  height: 375px;
}
.k-card .k-card-body {
  padding: 24px;
}

.fs-icon-button {
  background-color: $fs-body-bg;
  color: $fs-body-text;
}
.fs-app-version {
  color: $fs-body-bg-lighter-more;
}
.fs-form-help {
  color: $fs-body-bg-lighter-more;
  font-style: italic;
  font-size: 0.8em;
}

#logo {
  background-color: rgb(12, 20, 39) !important;
  padding: 20px 20px 10px 10px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.12));
}

#title {
  background-color: rgb(12, 20, 39) !important;
  border-bottom: 1px solid #172340;
}

#footer {
  background-color: $fs-body-bg;
}

#navigation {
  background-color: rgb(12, 20, 39) !important;
  border-color: var(--kendo-color-border, rgba(0, 0, 0, 0.12));
  border-width: 2px;
  border-style: solid;
}

.k-appbar-bottom {
  background-color: $fs-body-bg;
  color: $fs-color-white;
  font-size: 16px;
  font-weight: bold;
}

.k-appbar-top {
  background-color: transparent;
  font-size: 20px;
  color: $fs-color-white;
  margin: 8px;
}

.k-appbar {
  box-shadow: none;
}

.k-card-header {
  background-color: rgb(18, 30, 59);
}

.k-card {
  background-color: rgb(12, 20, 39) !important;
  box-shadow: none;
  border: 1px solid #172340 !important;
  border-radius: 8px !important;
}

#app-nav {
  box-shadow: none;
  border: 1px solid #172340 !important;
  border-radius: 8px !important;
}

.k-m-4 {
  color: $fs-color-white;
}

.k-license-panelbar-custom .k-panelbar-content {
  background-color: rgb(12, 20, 39) !important;
  color: $fs-color-white !important;
}

.k-license-panelbar-custom .k-link,
.k-license-panelbar-custom .k-link.k-selected,
.k-license-panelbar-custom .k-link.k-selected.k-focus,
.k-license-panelbar-custom .k-link:focus,
.k-license-panelbar-custom .k-link.k-selected:focus,
.k-license-panelbar-custom
  .k-link.k-selected.k-focus
  .k-license-panelbar-custom
  .k-item
  > .k-link.k-selected,
.k-license-panelbar-custom
  .k-link.k-selected.k-focus
  .k-license-panelbar-custom
  .k-item
  > .k-link.k-selected,
.k-license-panelbar-custom .k-panelbar-header > .k-link.k-selected {
  color: #dfd8d8 !important;
  background-color: rgb(18, 30, 59) !important;
  height: 75px;
  width: 100%;
  padding: 1px 24px;
  font-size: 18px;
  outline: none;
}

.k-license-panelbar-custom .k-link:hover,
.k-license-panelbar-custom .k-link.k-selected:hover {
  background-color: rgba(18, 30, 59, 0.9) !important;
}

.k-primary-solid-primary,
.k-primary-solid-primary:disabled,
.k-primary-solid-primary .k-disabled {
  background-color: #3f51b5 !important;
  color: $fs-color-white;
}

.k-primary-solid-secondary {
  background-color: #e51a5f !important;
}

.k-card-divider {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.f-license-content {
  font-size: 16px;
}

.fs-license-btngroup {
  padding-bottom: 15px;
}
.fs-login-btngroup {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
.k-label {
  color: #5a5a5a;
}
.app-login .k-label {
  color: $fs-color-white;
}
.error-message {
  color: #ef1801;
  font-size: 18px;
  font-style: normal;
}
.app-login .k-card {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-login .k-form {
  width: 460px;
  margin-top: 80px;
}
.hint {
  color: #e6e6e6;
  font-size: 18px;
}
.k-input.k-input-solid {
  color: $fs-body-bg;
}
.fs-resetpassword-dialog {
  margin: 25px;
  text-align: center;
}
.fs-resetpassword-div {
  display: flex;
  justify-content: center;
}
.fs-resetpassword-passwordmatch {
  color: red;
  font-size: 0.6rem;
}

.fs-product-card {
  padding-left: 20px;
  padding-right: 20px;
}
.fs-product-card > .k-card-header,
.fs-package-cards .k-card-header {
  background-color: rgb(12, 20, 39) !important;
  margin-top: 30px;
}
.fs-product-card > .k-card-body {
  padding: 0px 0px 0px 10px;
}
.fs-product-card > .k-card-actions {
  margin-top: 25px;
  margin-bottom: 25px;
}
.fs-add-product .k-window,
.fs-dialogbox .k-window {
  background-color: $fs-color-white !important;
}
.fs-add-product .k-window-titlebar {
  background-color: #2b377b !important;
}
.fs-add-product .k-upload-button,
.fs-documentation .k-upload-button, .k-dropdown-action {
  color: black !important;
}
.fs-package-card .k-card-footer {
  align-self: center;
}
.fs-dialogbox .k-window {
  height: 220px;
}
.fs-dialogbox .k-window-titlebar,
.fs-add-dialogbox .k-window-titlebar {
  background-color: #2b377b !important;
  opacity: 0.8;
}
.fs-dialogbox p,
.fs-add-dialogbox p {
  font-size: 1.2em;
  color: black;
  margin-bottom: 10px;
}
.fs-dialogbox .k-dialog-actions {
  font-size: 1.5em;
}
.fs-customers,
.fs-users {
  font-size: 14px;
}
.fs-customers .k-card-header,
.fs-users .k-card-header {
  background-color: rgb(12, 20, 39) !important;
  font-weight: bold;
  margin-left: 9px;
  margin-top: 20px;
  height: 30px;
}


 .k-grid-header .k-table-th {
  background-color: $fs-color-white;
  color: #757575 !important;
  font-weight: 700;
}
.fs-add-dialogbox .k-window-content {
  background-color: $fs-color-white !important;
  width: 640px;
}

.fs-form-2-column-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.fs-form-field {
  display: flex;
  flex-direction: column;
}

.fs-form-3-column-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column: span 2;
  gap: 1rem;
}

.fs-form-field-full {
  grid-column: span 2;
}
.k-multiselect.k-input
  .k-input-values
  .k-chip-list.k-chip-list-md
  .k-chip.k-chip-solid-base {
  background-color: $fs-color-white !important;
  color: $fs-body-bg !important;
}
.fs-email {
  margin-left: auto !important;
}

.fs-role-selection > .k-dropdownlist.k-picker-solid {
  color: #757575 !important;
}

/* edit licenses */

.k-form-fieldset {
  border: none; /* Remove default fieldset border */
  padding: 0; /* Remove default padding */
  margin: 0;
  width: 100%; /* Ensure the fieldset spans the container */
}

.k-form .k-form-field > .k-label {
  color: rgb(90, 90, 90);
}

.k-form-field {
  width: 100%; /* Ensure the field spans the container */
}

.form-row {
  display: flex;
  gap: 20px; /* Space between inputs */
  width: 100%; /* Ensure the row spans the container */
}

.form-row > .k-textbox,
.form-row > .k-datepicker,
.form-row > .LicenceTextInputFieldReadOnly,
.form-row > .LicenceDateInputFieldReadOnly {
  flex: 1; /* Make inputs stretch to fill the row */
  width: 100%; /* Ensure inputs take all available space */
}

textarea,
.form-row:last-child .LicenceTextInputFieldReadOnly {
  width: 100%; /* Full width for description */
  height: 100px; /* Adjust description height */
  resize: vertical;
}
.form-row:last-child {
  flex-direction: column; /* Ensure the description field spans the full row */
}

h2 {
  color: white;
}

.k-panelbar > .k-item > .k-link,
.k-panelbar > .k-panelbar-header > .k-link {
  color: white;
  background-color: rgb(18, 30, 59);
  opacity: 0.9;
  height: 74px;
}

.k-panelbar > .k-panelbar-content,
.k-content {
  background-color: rgb(12, 20, 39);
  color: white;
}

.k-panelbar > .k-item > .k-link.k-selected:focus,
.k-panelbar > .k-item > .k-link.k-selected.k-focus,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:focus,
.k-panelbar > .k-panelbar-header > .k-link.k-selected.k-focus {
  background-color: rgb(18, 30, 59);
  opacity: 0.9;
}

/* Default selected state */
.k-panelbar > .k-item > .k-link.k-selected,
.k-panelbar > .k-panelbar-header > .k-link.k-selected {
  background-color: rgb(18, 30, 59); /* Selected state color */
  color: inherit; /* Keep text color consistent */
  opacity: 0.9;
}

/* Hover state for selected items */
.k-panelbar > .k-item > .k-link.k-selected:hover,
.k-panelbar > .k-panelbar-header > .k-link.k-selected:hover {
  background-color: #627b8c; /* Hover color */
}

.k-panelbar {
  border: none;
  box-shadow: none;
}

.k-panelbar > .k-panelbar-header {
  border-style: none;
}

.k-panelbar .k-item {
  border-bottom: none !important; /* Remove the bottom border */
}

.k-panelbar .k-link {
  border-top: none !important; /* Remove the top border */
}

.k-panelbar-item-text {
  font-size: 18px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.k-input-inner {
  color: #070d19 !important;
}

.submit-button {
  background-color: var(--kendo-color-primary-hover, #3a4ba7);
}

.card-header-content h2{
  font-weight: lighter;
}
.k-config-button{
  height: 39px !important;  
  margin-top: "1px" !important;
}
.k-card-list > .k-card > .k-card-actions {
  padding-left: 20px;
  padding-bottom: 20px;
}
.k-margin-top-30 {
  margin-top: 30px;
}
.k-checkbox-bg {
  background-color: #2b377b;
}

.fs-dashboard-card {
  padding-left: 20px;
  padding-right: 20px;
}
.fs-dashboard-card > .k-card-header{
    background-color: transparent !important;
}

/* new license */

.licenseSet {
  width: 100%;
}

.listbox-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .listbox-container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .listbox-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .listbox-container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .listbox-container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .listbox-container {
    max-width: 1320px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.justify-content-center {
  justify-content: center;
}
.col {
  flex: 1 0 0%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative; /* Ensure columns can stack properly */
}

.k-pr-2 {
  padding-right: 2rem;
}

.k-actions .k-button-solid-base,
.k-form-buttons .k-button-solid-base,
.k-edit-buttons .k-button-solid-base,
.k-action-buttons .k-button-solid-base,
.k-columnmenu-actions .k-button-solid-base {
  background-color: #3f51b5;
}